import { ROLES } from '../../constants';

export const TAB_STATES_ROLES = {
  TRANSACTIONS: [
    ROLES.SUPPORT_ROLE,
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ],
  RFI_TRANSACTIONS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_OPS_MANAGER,
    ROLES.FALCON_DEVELOPER
  ],
  CLIENTS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_CLIENT_MANAGER,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ],
  VENDORS: [
    ROLES.ADMIN_ROLE,
    ROLES.FALCON_VENDOR_MANAGER,
    ROLES.FALCON_ADMIN,
    ROLES.FALCON_DEVELOPER
  ]
};

export const TAB_STATES = {
  TRANSACTIONS: 'TRANSACTIONS',
  RFI_TRANSACTIONS: 'RFI_TRANSACTIONS',
  CLIENTS: 'CLIENTS',
  VENDORS: 'VENDORS'
};

// The Mapping is key value
export const TRANSACTIONS_FILTER_TYPES = {
  STATUS: "status",
  PAYOUT_CURRENCY: "payoutCurrency",
  CLIENT: "client",
}

export const FALCON_STATUS = {
  NEW: "NEW",
  VALIDATION_FAILED: "VALIDATION_FAILED",
  CREATED: "CREATED",
  REJECTED: "REJECTED",
  QUEUED: "QUEUED",
  ON_HOLD: "ON_HOLD",
  PROCESSING: "PROCESSING",
  ADDITIONAL_DOCUMENTS_REQUIRED: "ADDITIONAL_DOCUMENTS_REQUIRED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
};

export const FALCON_TRANSACTION_DEFAULT_FILTERS = {
  status: ['PROCESSING'],
  payoutCurrency: ['INR'],
};

export const FALCON_RFI_TRANSACTION_DEFAULT_FILTERS = {
  partner: ["ARMSTRONG_PARTNER_DASHBOARD"],
  status: ["PENDING"]
};

// For the correct order in status funnel component
export const FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST = [
    FALCON_STATUS.PROCESSING,
    FALCON_STATUS.QUEUED,
    FALCON_STATUS.FAILED,
    FALCON_STATUS.EXPIRED,
    FALCON_STATUS.COMPLETED,
]

export const FALCON_TRANSACTION_EXPANDED_DISPLAY_STATUS_COUNT_LIST = [
    ...FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST,
    FALCON_STATUS.NEW,
    FALCON_STATUS.CREATED,
    FALCON_STATUS.VALIDATION_FAILED,
    FALCON_STATUS.REJECTED,
    FALCON_STATUS.ON_HOLD,
    FALCON_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED,
]

export const FORCE_FAIL_TRANSACTION_COMMENT_TYPE_CONFIG = [
  {
    key: 'CREATE_PAYOUT_FAILED_EXTERNAL_STATUS',
    value: 'CREATE_PAYOUT_FAILED_EXTERNAL_STATUS',
    label: 'CREATE_PAYOUT_FAILED_EXTERNAL_STATUS',
    isDisabled: false,
  },
  {
    key: 'COMPLETED_NOT_RECEIVED_EXTERNAL_STATUS',
    value: 'COMPLETED_NOT_RECEIVED_EXTERNAL_STATUS',
    label: 'COMPLETED_NOT_RECEIVED_EXTERNAL_STATUS',
    isDisabled: false
  }
];

export const VENDORS_VIEW_MODE = {
  KEY: 'VIEW_MODE',
  CARD: "CARD",
  TABLE: "TABLE"
}