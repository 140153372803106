import ContentLayout from '../../layouts/ContentLayout';
import {
  Grid,
  VStack,
  Heading,
  Tabs,
  TabList,
  Tab,
  Box,
  Text,
  GridItem,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { kebabCaseToSpaceSeperate } from '../../utils';
import react, { useState } from 'react';
import BalancesScreen from '../../components/RdaDashboard/Balances';
import TxnScreenTemplate from '../../components/RdaDashboard/Transactions/TxnScreenTemplate';
import Holiday from '../../components/RdaDashboard/Holidays/Holiday';
import {
  getClearBankPartnerTxns,
  getOverallTxns,
  getRdaGbpBalance,
  getTrueLayerBalance,
  getTrueLayerPartnerTxns,
  getYblBalance,
  getYblPartnerTxns,
  getYblPotBalance,
  getClearBankAccountDetails,
  getOperatingAccountBalance,
  getSafeGaurdingBalance,
  sweepOutBalanceSafeGuarding,
  withdrawFundsFromSafeguardingAccount,
  withdrawFundsFromOperatingAccountRDA,
  withdrawFundsFromOperatingAccountVDA,
  withdrawRefundedFundsFromOperating
} from '../../store/actions/rdaDashboardActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { useDispatch, useSelector } from 'react-redux';
import { updateRdaDashboardReqData } from '../../store/actions/reqDataActions';
import Rda from '../Rda';
import BalanceTemplate from '../../components/RdaDashboard/Balances/BalanceTemplate';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants';
const RdaDashboard = () => {
  const { hasAnyAuthority } = useAuthRole();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const TAB_STATES = {
    // OVERALL: 'OVERALL',
    // TRUE_LAYER: 'TRUE_LAYER',
    CLEAR_BANK: 'CLEAR_BANK',
    YBL: 'YBL',
    // BALANCES: 'BALANCES',
    TRADES: 'TRADES',
    BALANCE_SWEEPER: 'BALANCE_SWEEPER'
    // REFUNDS: 'REFUNDS',
    // MANUAL_TRANSACTIONS: 'MANUAL_TRANSACTIONS'
  };
  const balanceSweeperButtons = [
    {
      title: 'Sweep Out Safeguarding',
      onClick: sweepOutBalanceSafeGuarding({
        onComplete: () => setIsModalLoading(false)
      })
    },
    {
      title: 'Withdraw from Safeguarding Account',
      onClick: withdrawFundsFromSafeguardingAccount({
        onComplete: () => setIsModalLoading(false)
      })
    },
    {
      title: 'Withdraw from Operating Account (RDA)',
      onClick: withdrawFundsFromOperatingAccountRDA({
        onComplete: () => setIsModalLoading(false)
      })
    },
    {
      title: 'Withdraw from Operating Account (VDA)',
      onClick: withdrawFundsFromOperatingAccountVDA({
        onComplete: () => setIsModalLoading(false)
      })
    },
    {
      title: 'Withdraw Refunded Funds from Operating Account',
      onClick: withdrawRefundedFundsFromOperating({
        onComplete: () => setIsModalLoading(false)
      })
    }
  ];
  const [sweeperIndex, setSweeperIndex] = useState();
  const [isModalLoading, setIsModalLoading] = useState();
  const rdaDashboardReqData = useSelector(
    (state) => state?.reqData?.rdaDashboard
  );

  const {
    overall: overallReqParams,
    truelayer: tlReqParams,
    clearbank: cbReqParams,
    ybl: yblReqParams
  } = rdaDashboardReqData;
  const { overall, clearbank, ybl, truelayer } = useSelector(
    (state) => state?.rdaDashboard
  );

  const [selectedTab, setSelectedTab] = useState(TAB_STATES.CLEAR_BANK);

  const dispatch = useDispatch();

  const handleParamsChange = (e) => {
    const [prefix, name] = String(e.target.name).split('.');
    const value = e.target.value;

    console.log({ prefix, value, name });

    dispatch(
      updateRdaDashboardReqData({
        ...rdaDashboardReqData,
        [prefix]: {
          ...rdaDashboardReqData[prefix],
          [name]: value
        }
      })
    );
  };

  const handlePageChange = (value, prefix) => {
    console.log({ value, prefix });
    dispatch(
      updateRdaDashboardReqData({
        ...rdaDashboardReqData,
        [prefix]: {
          ...rdaDashboardReqData[prefix],
          pageNumber: value - 1
        }
      })
    );
  };

  return (
    <ContentLayout>
      <Grid
        color={'white'}
        gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}
      >
        <ModalLayout isOpen={isOpen} onClose={onClose} size={'md'}>
          <ConfirmModal
            handleSubmit={() => {
              setIsModalLoading(true);
              dispatch(balanceSweeperButtons[sweeperIndex].onClick);
              setSweeperIndex(null);
              onClose();
            }}
            prompt={'Are you sure you want to confirm'}
            isLoading={isModalLoading}
          />
        </ModalLayout>
        <VStack
          width={{ base: 'full', lg: '70vw' }}
          alignItems={'stretch'}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          className="scroll"
        >
          <Heading size={'lg'} color={'white'}>
            RDA Dashboard
          </Heading>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={(e) => {
              setSelectedTab(Object.keys(TAB_STATES)[e]);
            }}
            defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
          >
            <TabList color={'white'}>
              {Object.keys(TAB_STATES).map((tabState, ind) => (
                <Tab
                  key={ind}
                  _selected={{
                    color: 'brand.500',
                    border: '1px solid white',
                    borderBottom: '1px solid black'
                  }}
                >
                  {kebabCaseToSpaceSeperate(tabState)}
                </Tab>
              ))}
            </TabList>
          </Tabs>
          <VStack gap={4} alignItems={'stretch'} width={'full'} py={5} px={4}>
            {/* {selectedTab === TAB_STATES.OVERALL && (
              <>
                <BalanceTemplate
                  title={'RDA GBP Balance'}
                  action={getRdaGbpBalance}
                />
                <TxnScreenTemplate
                  txnData={overall}
                  reqParams={overallReqParams}
                  action={getOverallTxns}
                  title={''}
                  namePrefix={'overall'}
                  handlePageChange={handlePageChange}
                  handleParamsChange={handleParamsChange}
                  cardType={'OVERALL'}
                  featureFilters={{
                    fromDate: {
                      name: 'fromDate',
                      prefix: 'overall',
                      filterType: 'DATE'
                    },
                    pageSize: {
                      name: 'pageSize',
                      prefix: 'overall',
                      filterType: 'SELECT',
                      options: [10, 25, 50]
                    }
                  }}
                />
              </>
            )} */}
            {/* {selectedTab === TAB_STATES.TRUE_LAYER && (
              <>
                <BalanceTemplate
                  title={'True Layer Balance'}
                  action={getTrueLayerBalance}
                />
                <TxnScreenTemplate
                  txnData={truelayer}
                  reqParams={tlReqParams}
                  action={getTrueLayerPartnerTxns}
                  title={'True Layer'}
                  namePrefix={'truelayer'}
                  handlePageChange={handlePageChange}
                  handleParamsChange={handleParamsChange}
                  cardType={'TRUE_LAYER'}
                  featureFilters={{
                    fromDate: {
                      name: 'fromDate',
                      prefix: 'truelayer',
                      filterType: 'DATE'
                    },
                    pageSize: {
                      name: 'pageSize',
                      prefix: 'truelayer',
                      filterType: 'SELECT',
                      options: [10, 25, 50]
                    }
                  }}
                />
              </>
            )} */}
            {selectedTab === TAB_STATES.CLEAR_BANK && (
              <>
                <BalanceTemplate
                  title={'Safeguarding Balance'}
                  action={getSafeGaurdingBalance}
                />
                <BalanceTemplate
                  title={'Operating Balance'}
                  action={getOperatingAccountBalance}
                />
                <BalanceTemplate
                  title={'True Layer Balance'}
                  action={getTrueLayerBalance}
                  args={{ decimalAdjustment: 100 }}
                />
                <TxnScreenTemplate
                  txnData={clearbank}
                  reqParams={cbReqParams}
                  action={getClearBankPartnerTxns}
                  title={'Clearbank'}
                  namePrefix={'clearbank'}
                  handlePageChange={handlePageChange}
                  handleParamsChange={handleParamsChange}
                  cardType={'CLEAR_BANK'}
                  featureFilters={{
                    transactionType: {
                      name: 'transactionType',
                      prefix: 'clearbank',
                      filterType: 'SELECT',
                      options: ['CREATED', 'PROCESSING', 'COMPLETED']
                    },
                    fromDate: {
                      name: 'fromDate',
                      prefix: 'truelayer',
                      filterType: 'DATE'
                    },
                    pageSize: {
                      name: 'pageSize',
                      prefix: 'truelayer',
                      filterType: 'SELECT',
                      options: [10, 25, 50]
                    }
                  }}
                />
              </>
            )}
            {selectedTab === TAB_STATES.YBL && (
              <>
                <BalanceTemplate
                  title={'Ybl Balance'}
                  action={getYblBalance}
                  args={{ country: 'India' }}
                />
                <BalanceTemplate
                  title={'YBL Pot Balance'}
                  action={getYblPotBalance}
                  args={{ country: 'India' }}
                />
                {/* <TxnScreenTemplate
                  txnData={ybl}
                  reqParams={yblReqParams}
                  action={getYblPartnerTxns}
                  title={'YBL'}
                  namePrefix={'ybl'}
                  handlePageChange={handlePageChange}
                  handleParamsChange={handleParamsChange}
                  cardType={'YBL'}
                /> */}
              </>
            )}
            {selectedTab === TAB_STATES.TRADES && <Rda />}
            {/* {[TAB_STATES.REFUNDS, TAB_STATES.MANUAL_TRANSACTIONS].includes(
              selectedTab
            ) && (
              <Text color={'white'} fontSize={'md'}>
                Page under progress
              </Text>
            )} */}
            {selectedTab === TAB_STATES.BALANCE_SWEEPER && (
              <VStack spacing={4} align="stretch" w="100%">
                {hasAnyAuthority(
                  ROLES.ADMIN_ROLE,
                  ROLES.RE_INITIATE_YBL_ROLE,
                  ROLES.SWEEP_WITHDRAWAL_ROLE
                ) ? (
                  balanceSweeperButtons.map((button, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      colorScheme="brand"
                      size="md"
                      onClick={() => {
                        setSweeperIndex(index);
                        onOpen();
                      }}
                      isFullWidth
                    >
                      {button.title}
                    </Button>
                  ))
                ) : (
                  <Text>No Actions Available</Text>
                )}
              </VStack>
            )}
          </VStack>
        </VStack>
        <Box>
          {selectedTab === TAB_STATES.TRADES && (
            <VStack justifyContent={'center'} gap="20px">
              <Holiday countryCode="IN" />
              <Holiday countryCode="GB" />
            </VStack>
          )}
        </Box>
      </Grid>
    </ContentLayout>
  );
};

export default RdaDashboard;
