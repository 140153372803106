import React, { useState } from 'react';
import {
  Box,
  HStack,
  Select,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import { searches } from './utils';
// import InputComponent from '../Input';
import { MdSearch } from 'react-icons/md';
import InputComponent from '../Input';

const SearchBox = ({ searches, handleSearchChange }) => {
  // Initialize state from searches.default
  const { searchKey: defaultSearchKey, placeholder: defaultPlaceholder } =
    searches.find((transaction) => transaction.isDefault === true);

  const [searchKey, setSearchKey] = useState(defaultSearchKey);
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
  const [searchValue, setSearchValue] = useState('');
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    handleSearchChange({
      searchKey,
      searchValue: e.target.value
    });
  };
  const handleSearchKeyChange = (e) => {
    const selectedKey = e.target.value;
    setSearchKey(selectedKey);

    // Find the corresponding placeholder, fallback to default if not found
    const selectedTransaction = searches.find(
      (transaction) => transaction.searchKey === selectedKey
    );
    setPlaceholder(selectedTransaction?.placeholder || defaultPlaceholder);
  };

  return (
    <Box
      width="100%"
      borderRadius="md"
      shadow="lg"
      border="1px solid"
      borderColor="altGray.600"
    >
      <FormControl>
        <HStack spacing={4} w="100%">
          {searches.length > 1 && (
            <Select
              value={searchKey}
              onChange={handleSearchKeyChange}
              bg="altGray.200"
              color="shadedGray.50"
              focusBorderColor="brand.400"
              style={{
                border: 0,
                background: '#414141',
                borderRadius: '12px',
                gap: '12px',
                margin: '0',
                width: 'auto'
              }}
              _hover={{ bg: 'altGray.300' }}
            >
              {searches.map((obj, index) => (
                <option key={index} value={obj.searchKey}>
                  {obj.display}
                </option>
              ))}
            </Select>
          )}
          <InputComponent
            type={'text'}
            size={'md'}
            name={'search'}
            leftElement={<MdSearch size="1.25rem" />}
            handleChange={handleChange}
            value={searchValue}
            autoComplete={'false'}
            spellCheck={false}
            placeholder={placeholder}
          />
        </HStack>
      </FormControl>
    </Box>
  );
};

export default SearchBox;
