export const TRANSACTION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT'
};

export const TRANSACTION_TYPE_CONFIG = [
  {
    key: 'CREDIT',
    value: 'CREDIT',
    label: 'CREDIT',
    isDisabled: false
  },
  {
    key: 'DEBIT',
    value: 'DEBIT',
    label: 'DEBIT',
    isDisabled: true
  }
]

export const UPDATE_CLIENT_BALANCE_MODAL_FORM_CONFIG = [
  {
    value: "referenceId",
    label: "Reference Id",
    placeholder: "Enter Reference Id here...",
    type: "input",
    inputType: "string",
    size: "md",
    isEditable: true,
    isRequired: true,
  },
  {
    value: "transactionType",
    label: "Transaction Type",
    caption: null,
    type: "select",
    size: "md",
    placeholder: "Select a Transaction Type",
    options : TRANSACTION_TYPE_CONFIG?.map((type) => {
      return {
        value: type.value,
        key: type.key,
        label: type.label,
        isDisabled: type.isDisabled
      };
    }),
    isEditable: true,
    isRequired: true,
  },
  {
    value: "balance",
    label: "Balance to update",
    caption: null,
    type: "input",
    inputType: "number",
    defaultValue: 1,
    isEditable: true,
    isRequired: true,
    size: "md",
    minValue: 1,
  },
];
