import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearOrderDetails,
  forceFailOrder,
  forceSyncLuluOrder,
  forceSyncThunesPayment,
  getOrderById,
  payoutWithThunes,
  reinitiateThunesFulfillment,
  updateBeneficiaryDetails,
  updateOrderBeneficiary,
  failAndRefund,
  reInitiateYblFulfillment
} from '../../store/actions/ordersActions';
import DetailsTable from './DetailsTable';
import { getOrderDetailsTable } from './utils';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import {
  BsArrowRight,
  BsArrowsCollapse,
  BsChevronExpand
} from 'react-icons/bs';
import Notes from './Notes';
import ModalLayout from '../Modal/ModalLayout';
import ConfirmModal from '../Modal/ConfirmForceInitiate';
import useAuthRole from '../../hooks/useAuthRole';
import { ORDER_ACQUIRER, ORDER_TYPE, ROLES } from '../../constants';
import { AiFillStop } from 'react-icons/ai';
import TicketDetails from './TicketDetails';
import UpdateBeneficiartModal from './UpdateBeneficiaryModal';
import UpdateOrderBeneficiaryModal from './UpdateOrderBeneficiary';
import { FaInfo, FaInfoCircle } from 'react-icons/fa';
import OrderReceiptDetails from './OrderReceipt';
import { updateRfiAvailableTypes } from '../../store/actions/rfiActions';
import ReusableModal from '../FalconServiceDashboard/ReuseableModal';

const OrderDetailsCard = ({
  orderId,
  isSidebar = false,
  showNavLink = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAnyAuthority } = useAuthRole();
  const [orderDetailsTable, setOrderDetailsTable] = useState([]);
  const [showAllPayments, setShowAllPayments] = useState(false);
  const [currFulfillment, setCurrFulfillment] = useState(null);
  const [isRefund, setRefund] = useState(false);
  const [yblFulfillment, setYblFulfillment] = useState({});
  const { data: orderDetails, isLoading } = useSelector(
    (state) => state.orders.orderDetails
  );
  const {
    isOpen: isConfirmFailModalOpen,
    onOpen: onConfirmFailModalOpen,
    onClose: onConfirmFailModalClose
  } = useDisclosure();

  const {
    isOpen: isReinitiateOpen,
    onOpen: onReinitiateOpen,
    onClose: onReinitiateClose
  } = useDisclosure();

  const {
    isOpen: isPayoutWithThunesOpen,
    onOpen: onPayoutWithThunesOpen,
    onClose: onPayoutWithThunesClose
  } = useDisclosure();

  const {
    isOpen: isReInitiateYblOpen,
    onOpen: onReInitiateYblOpen,
    onClose: onReInitiateYblClose
  } = useDisclosure();
  // const {
  //   isOpen: isUpdateBeneficiaryOpen,
  //   onOpen: onUpdateBeneficiaryOpen,
  //   onClose: onUpdateBeneficiaryClose
  // } = useDisclosure();
  // const [isUpdateBeneficiaryLoading, setIsUpdateBeneficiaryLoading] =
  //   useState(false);

  const {
    isOpen: isUpdateOrderBeneficiaryOpen,
    onOpen: onUpdateOrderBeneficiaryOpen,
    onClose: onUpdateOrderBeneficiaryClose
  } = useDisclosure();
  const [isUpdateOrderBeneficiaryLoading, setIsUpdateOrderBeneficiaryLoading] =
    useState(false);

  const [isFailingOrder, setIsFailingOrder] = useState(false);
  const [isReinitiating, setIsReinitiating] = useState(false);
  const [isForceSyncPaymentLoading, setIsForceSyncPaymentLoading] =
    useState(false);
  const [isReInitiateYblLoading, setIsReInitiateYblLoading] = useState(false);
  const [isPayoutWithThunesLoading, setIsPayoutWithThunesLoading] =
    useState(false);

  useEffect(() => {
    dispatch(getOrderById({ orderId }));
  }, [orderId, dispatch]);

  useEffect(() => {
    setOrderDetailsTable(
      orderDetails ? getOrderDetailsTable(orderDetails) : null
    );
  }, [orderDetails]);

  useEffect(() => {
    return () => dispatch(clearOrderDetails());
  }, [dispatch]);

  const yblReinitiateButton = (yblFulfillmentId) => (
    <Button
      size={'sm'}
      isLoading={isReInitiateYblLoading}
      variant={'outline'}
      colorScheme="green"
      onClick={() => {
        setYblFulfillment({
          userId: orderDetails.userId,
          orderId: orderDetails.orderId,
          fulfillmentId: yblFulfillmentId
        });
        onReInitiateYblOpen();
      }}
    >
      Re-Initiate
    </Button>
  );
  const toggleAllPayments = () => setShowAllPayments((prev) => !prev);
  if (isLoading)
    return (
      <Flex m={'1rem'} justifyContent={'center'} alignItems={'center'}>
        <Spinner color="brand.600" />
      </Flex>
    );
  const handleFailOrder = (refund) => {
    setIsFailingOrder(true);
    if (isRefund) {
      dispatch(
        failAndRefund({
          orderId: orderId,
          onFinish: () => {
            setIsFailingOrder(false);
            onConfirmFailModalClose();
          }
        })
      );
    } else {
      dispatch(
        forceFailOrder({
          orderId: orderId,
          onFinish: () => {
            setIsFailingOrder(false);
            onConfirmFailModalClose();
          }
        })
      );
    }
  };

  // const handleUpdateBeneficiaryOpen = () => {
  //   onUpdateBeneficiaryOpen();
  // };
  // const handleUpdateBeneficiaryClose = () => {
  //   if (isUpdateBeneficiaryLoading) return;
  //   onUpdateBeneficiaryClose();
  // };
  // const handleUpdateBeneficiarySubmit = (data) => {
  //   setIsUpdateBeneficiaryLoading(true);
  //   const payload = {
  //     accountHolderName: data?.accountHolderName,
  //     ifscCode: data?.IFSCCode,
  //     accountNumber: data?.accountNumber,
  //     address: ''
  //   };
  //   dispatch(
  //     updateBeneficiaryDetails({
  //       userId: orderDetails?.userId,
  //       beneficiaryId: orderDetails?.beneficiary?.beneficiaryId,
  //       payload,
  //       onSuccess: () => {
  //         onUpdateBeneficiaryClose();
  //       },
  //       onComplete: () => {
  //         setIsUpdateBeneficiaryLoading(false);
  //       }
  //     })
  //   );
  // };

  const handleUpdateOrderBeneficiarySubmit = (updatedBeneficiaryId) => {
    if (updatedBeneficiaryId === orderDetails?.beneficiary?.beneficiaryId) {
      onUpdateOrderBeneficiaryClose();
      return;
    }
    setIsUpdateOrderBeneficiaryLoading(true);
    dispatch(
      updateOrderBeneficiary({
        orderId,
        beneficiaryId: updatedBeneficiaryId,
        onSuccess: () => {
          onUpdateOrderBeneficiaryClose();
        },
        onComplete: () => {
          setIsUpdateOrderBeneficiaryLoading(false);
        }
      })
    );
  };

  const handleReInitiateFulfillment = () => {
    setIsReinitiating(true);
    dispatch(
      reinitiateThunesFulfillment({
        fulfillmentId: currFulfillment,
        onSuccess: () => {
          setCurrFulfillment(null);
          // onConfirmFailModalClose();
          onReinitiateClose();
        },
        onComplete: () => {
          setIsReinitiating(false);
        }
      })
    );
  };

  const handleForceSyncClick = (e) => {
    setIsForceSyncPaymentLoading(true);
    dispatch(
      forceSyncThunesPayment({
        orderId,
        onSuccess: () => {},
        onComplete: () => {
          setIsForceSyncPaymentLoading(false);
          dispatch(getOrderById({ orderId }));
        }
      })
    );
  };

  const handleLuluForceSyncClick = (e) => {
    setIsForceSyncPaymentLoading(true);
    dispatch(
      forceSyncLuluOrder({
        orderId,
        onSuccess: () => {},
        onComplete: () => {
          setIsForceSyncPaymentLoading(false);
          dispatch(
            getOrderById({
              orderId
            })
          );
        }
      })
    );
  };

  const handlePayoutWithThunesSubmit = () => {
    setIsPayoutWithThunesLoading(true);
    dispatch(
      payoutWithThunes({
        orderId,
        onSuccess: () => {
          onPayoutWithThunesClose();
          dispatch(getOrderById({ orderId }));
        },
        onComplete: () => {
          setIsPayoutWithThunesLoading(false);
        }
      })
    );
  };

  const handleCreateRFI = (list, link) => {
    dispatch(updateRfiAvailableTypes(list));
    navigate(link);
  };
  const handleReInitiateYblFulfillment = () => {
    dispatch(
      reInitiateYblFulfillment({
        yblFulfillmentData: yblFulfillment,
        onComplete: () => {
          setYblFulfillment(null);
        }
      })
    );
  };
  return (
    <Box position={'relative'}>
      <ModalLayout
        isOpen={isReInitiateYblOpen}
        onClose={onReInitiateYblClose}
        size="md"
      >
        <ConfirmModal
          handleSubmit={(orderDetails) => {
            handleReInitiateYblFulfillment();
          }}
          isLoading={isReInitiateYblLoading}
          prompt="Are you sure you want to Re-Initiate this YBL fulfillment order"
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isConfirmFailModalOpen}
        onClose={() => {
          onConfirmFailModalClose();
          setRefund(false);
        }}
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={handleFailOrder}
          isLoading={isFailingOrder}
          prompt={
            isRefund
              ? `Fail and Refund order ${orderId}`
              : `Fail order ${orderId}?`
          }
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isReinitiateOpen}
        onClose={onReinitiateClose}
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={handleReInitiateFulfillment}
          isLoading={isReinitiating}
          prompt={`Reinitiate Fulfillment?`}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isUpdateOrderBeneficiaryOpen}
        onClose={onUpdateOrderBeneficiaryClose}
      >
        <UpdateOrderBeneficiaryModal
          userId={orderDetails?.userId}
          initialBeneficiaryId={orderDetails?.beneficiary?.beneficiaryId}
          isLoading={isUpdateOrderBeneficiaryLoading}
          onCancel={onUpdateOrderBeneficiaryClose}
          onSubmit={handleUpdateOrderBeneficiarySubmit}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isPayoutWithThunesOpen}
        onClose={onPayoutWithThunesClose}
      >
        <ConfirmModal
          prompt={
            'This will initiate payout through thunes. Are you sure you want to continue?'
          }
          isLoading={isPayoutWithThunesLoading}
          handleSubmit={handlePayoutWithThunesSubmit}
        />
      </ModalLayout>
      <Box
        m={'1rem'}
        mr={0}
        pr={'1rem'}
        maxH={isSidebar ? '85vh' : 'none'}
        className="scroll-sm"
        overflow={isSidebar ? 'auto' : 'inherit'}
      >
        <VStack
          alignItems={'stretch'}
          divider={
            <StackDivider
              style={{ margin: '0' }}
              borderColor="whiteAlpha.300"
            />
          }
        >
          {orderDetailsTable?.length &&
            orderDetailsTable.map(
              (item, i) =>
                item?.data?.length &&
                (!item.defaultHidden || showAllPayments) && (
                  <Box key={item.title || i} pb={3}>
                    {i === 0 ? (
                      <Flex
                        justifyContent={'space-between'}
                        alignItems={'baseline'}
                        pr={isSidebar ? 2 : 6}
                      >
                        <Heading
                          fontSize={'2xl'}
                          color={'brand.500'}
                          mb={'0.5rem'}
                        >
                          {item.title}
                        </Heading>
                        {showNavLink && orderDetails?.userId && (
                          <Link
                            as={ReactLink}
                            to={`/user/${orderDetails.userId}?orderId=${orderId}`}
                            color="brand.500"
                            fontSize={'sm'}
                          >
                            <HStack>
                              <Text>User Details</Text>
                              <BsArrowRight display={'inline'} />
                            </HStack>
                          </Link>
                        )}
                      </Flex>
                    ) : (
                      <Heading
                        fontSize={'lg'}
                        color={'white'}
                        mb={'0.5rem'}
                        mt={4}
                      >
                        {item.title}
                      </Heading>
                    )}
                    {item.data && (
                      <DetailsTable
                        isSidebar={isSidebar}
                        details={item.data}
                        button={yblReinitiateButton}
                      />
                    )}
                    {item?.hasActionBtns &&
                      item.element === 'order_payment' && (
                        <>
                          <VStack alignItems={'stretch'} py={4}>
                            <Button
                              size={'sm'}
                              isLoading={isForceSyncPaymentLoading}
                              variant={'outline'}
                              colorScheme="red"
                              onClick={handleForceSyncClick}
                            >
                              Force Sync
                            </Button>
                          </VStack>
                        </>
                      )}

                    {/* {item?.hasActionBtns && item.element === 'lulu_data' && (
                      <>
                        <VStack alignItems={'stretch'} py={4}>
                          <Button
                            size={'sm'}
                            isLoading={isForceSyncPaymentLoading}
                            variant={'outline'}
                            colorScheme="red"
                            onClick={handleLuluForceSyncClick}
                          >
                            Force Sync Lulu Order
                          </Button>
                        </VStack>
                      </>
                    )} */}
                    {item?.hasActionBtns && item.element === 'beneficiary' && (
                      <HStack gap={2} marginY={3}>
                        {hasAnyAuthority(
                          ROLES.FULFILMENT_REINITIATE,
                          ROLES.ADMIN_ROLE
                        ) &&
                          orderDetails?.orderStatus !== 'COMPLETED' && (
                            <Button
                              width={'full'}
                              py={4}
                              size={'xs'}
                              variant={'outline'}
                              colorScheme="brand"
                              onClick={onUpdateOrderBeneficiaryOpen}
                            >
                              Update Order Beneficiary
                            </Button>
                          )}
                      </HStack>
                    )}
                    {item?.hasActionBtns &&
                      item.element === 'reinitiate_fulfillment' &&
                      orderDetails?.orderStatus !== 'COMPLETED' &&
                      hasAnyAuthority(
                        ROLES.ADMIN_ROLE,
                        ROLES.FULFILMENT_REINITIATE
                      ) && (
                        <>
                          <Button
                            marginY={3}
                            width={'full'}
                            size={'sm'}
                            variant={'outline'}
                            colorScheme="brand"
                            onClick={() => {
                              setCurrFulfillment(item?.fulfillmentId);
                              onReinitiateOpen();
                            }}
                          >
                            Reinitiate Fulfillment
                          </Button>
                        </>
                      )}
                    {item?.hasActionBtns &&
                      item?.element === 'off_ramp_reinitiate_with_thunes' &&
                      // orderDetails?.orderStatus === 'COMPLETED' &&
                      hasAnyAuthority(ROLES.ADMIN_ROLE) && (
                        <>
                          <Button
                            width={'full'}
                            colorScheme="brand"
                            variant={'outline'}
                            marginY={3}
                            onClick={onPayoutWithThunesOpen}
                          >
                            <HStack alignItems={'center'} gap={1}>
                              <Text>Payout with Thunes</Text>
                              <Tooltip
                                label="Trigger this only when the OFF_RAMP status is COMPLETED but user didn't get funds. 
                              This action will initiate payout through THUNES"
                              >
                                <Box>
                                  <FaInfoCircle color="#81EBAB" />
                                </Box>
                              </Tooltip>
                            </HStack>
                          </Button>
                        </>
                      )}
                    {item?.hasActionBtns && item?.element === 'rfi_details' && (
                      <>
                        <Button
                          width={'full'}
                          colorScheme="brand"
                          variant={'outline'}
                          marginY={3}
                          size={'sm'}
                          onClick={() => navigate(item?.navLink)}
                        >
                          View RFI Details
                        </Button>
                      </>
                    )}
                    {item?.hasActionBtns && item?.element === 'create_rfi' && (
                      <>
                        <Button
                          width={'full'}
                          colorScheme="brand"
                          variant={'outline'}
                          marginY={3}
                          size={'sm'}
                          onClick={() =>
                            handleCreateRFI(item?.list, item?.navLink)
                          }
                        >
                          Create RFI
                        </Button>
                      </>
                    )}
                    {item.title === 'Order Payment' &&
                    orderDetails.payments?.length > 1 ? (
                      <Button
                        variant={'link'}
                        color={'white'}
                        size={'sm'}
                        rightIcon={
                          showAllPayments ? (
                            <BsArrowsCollapse />
                          ) : (
                            <BsChevronExpand />
                          )
                        }
                        onClick={toggleAllPayments}
                        py={2}
                      >
                        All Payments
                      </Button>
                    ) : null}
                  </Box>
                )
            )}
          {orderDetails?.fulfillmentProvider === 'LULU' && (
            <>
              <VStack alignItems={'stretch'} py={4}>
                <Button
                  size={'sm'}
                  isLoading={isForceSyncPaymentLoading}
                  variant={'outline'}
                  colorScheme="red"
                  onClick={handleLuluForceSyncClick}
                >
                  Trigger Lulu Enquiry
                </Button>
              </VStack>
            </>
          )}
          {orderDetails?.orderType === ORDER_TYPE.MANUAL_PAYMENT && (
            <OrderReceiptDetails orderId={orderDetails?.orderId} />
          )}
          <Button
            variant={'link'}
            colorScheme="brand"
            onClick={() => {
              navigate(`/order/${orderDetails?.orderId}/screen-config`);
            }}
            marginY={'20px'}
          >
            View Compliance Screen Config
          </Button>
          <Notes
            note={orderDetails?.metadata?.details || null}
            Flagged={orderDetails?.metadata?.complianceFlagged}
            updatedByEmail={orderDetails?.metadata?.updatedByEmail}
            updatedAt={orderDetails?.metadata?.updatedAt}
            orderId={orderId}
          />

          <TicketDetails
            ticketData={orderDetails?.opsTicket}
            referenceId={
              !orderDetails?.opsTicket
                ? orderId
                : orderDetails?.opsTicket?.referenceId
            }
            ticketExists={Boolean(orderDetails?.opsTicket?.ticketId)}
            allowUpdateActions={hasAnyAuthority(ROLES.OPS_MANAGER_ROLE)}
          />
          {hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.FAIL_REFUND) && (
            <VStack w={'100%'} py={2} mt={2}>
              {orderDetails?.orderStatus !== 'FAILED' &&
                orderDetails?.orderStatus !== 'COMPLETED' && (
                  <Button
                    variant={'link'}
                    size={'sm'}
                    colorScheme="red"
                    rightIcon={<AiFillStop />}
                    onClick={onConfirmFailModalOpen}
                  >
                    Fail
                  </Button>
                )}

              {orderDetails?.orderStatus !== 'CREATED' &&
                orderDetails?.orderStatus !== 'COMPLETED' && (
                  <Button
                    variant={'link'}
                    size={'sm'}
                    colorScheme="red"
                    rightIcon={<AiFillStop />}
                    onClick={() => {
                      setRefund(true);
                      onConfirmFailModalOpen();
                    }}
                  >
                    Fail and Refund Order
                  </Button>
                )}
            </VStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default OrderDetailsCard;
