import { BENEFICIARY_BANK_ACCOUNT_OPTIONS } from '../../../constants';

export const FORCE_UPDATE_BENEFICIARY_VERIFICATION_FORM_CONFIG = [
  {
    value: "beneficiaryAccountType",
    name: "beneficiaryAccountType",
    label: "Choose the beneficiary account type:",
    placeholder: "Select Account Type",
    caption: null,
    isEditable: true,
    type: "select",
    size: "md",
    options : BENEFICIARY_BANK_ACCOUNT_OPTIONS?.map((type) => {
      return {
        value: type,
        key: type,
        label: type
      };
    }),
    isRequired: true,
  },
];