import api from '../../api';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../constants';
import { setAlert } from './alertActions';

// Clients Dashboard Actions
export const getClients = (reqData, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_FALCON_CLIENTS
    });
    const { data } = await api.falconDashboard.getClients(reqData);
    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_FALCON_CLIENTS_FAILURE,
      payload: error
    });
  }
};

export const getClientDetails =
  ({ client, currency }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_FALCON_CLIENT_DETAILS
      });
      const { data } = await api.falconDashboard.getClientDetails({
        client,
        currency
      });
      dispatch({
        type: types.GET_FALCON_CLIENT_DETAILS_SUCCESS,
        payload: { data }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_FALCON_CLIENT_DETAILS_FAILURE,
        payload: error
      });
    }
  };

export const getClientBalanceDetails =
  ({ client }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_FALCON_CLIENT_BALANCE_DETAILS
      });
      const { data } = await api.falconDashboard.getClientBalanceDetails({
        client
      });
      dispatch({
        type: types.GET_FALCON_CLIENT_BALANCE_DETAILS_SUCCESS,
        payload: { data }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_FALCON_CLIENT_BALANCE_DETAILS_FAILURE,
        payload: error
      });
    }
  };

export const getClientFilters =
  ({ onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.falconDashboard.getSearchConfigs('clients');
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const createClient =
  ({ data, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CREATE_FALCON_CLIENT
      });
      const _ = await api.falconDashboard.createClient(data);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.CREATE_FALCON_CLIENT_FAILURE,
        payload: error
      });
    }
  };

export const updateClient =
  ({ req, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_FALCON_CLIENT
      });
      const { data } = await api.falconDashboard.updateClient(req);
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.UPDATE_FALCON_CLIENT_FAILURE,
        payload: error
      });
    }
  };

export const updateClientBalance =
  ({ client, payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_FALCON_CLIENT_BALANCE
      });
      const { data } = await api.falconDashboard.updateClientBalance({
        client,
        payload
      });
      onSuccess && onSuccess(data);

      dispatch(
        setAlert({
          title: 'Client balance successfully updated!',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

// Transaction Dashboard Actions
export const getTransactions =
  ({ requestBody, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_FALCON_TRANSACTIONS
      });
      if (requestBody) {
        const { data } = await api.falconDashboard.getTransactions({
          requestBody: requestBody
        });
        dispatch({
          type: types.GET_FALCON_TRANSACTIONS_SUCCESS,
          payload: data
        });
      }
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateFalconTransactionDashboardRequestBody = (reqBody) => ({
  type: types.UPDATE_FALCON_TRANSACTION_DASHBOARD_REQ_DATA,
  payload: {
    ...reqBody
  }
});

// Get the top 5 credit transactions
export const getClientTransactionHistory =
  ({ client }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_FALCON_CLIENT_BALANCE_HISTORY
      });

      const { data } = await api.falconDashboard.getClientTransactionHistory({
        client
      });
      return data;
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      throw error;
    }
  };

export const getFalconTransactionDetails =
  ({ selectedTransaction, transactionId, recipientId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_TRANSACTION_DETAILS
      });
      const { data } = await api.falconDashboard.getFalconTransactionData({
        transactionId,
        recipientId
      });

      const { falconRecipient, transactionPayouts } = data;
      dispatch({
        type: types.GET_TRANSACTION_DETAILS_SUCCESS,
        payload: { selectedTransaction, falconRecipient, transactionPayouts }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const updateClientPayoutPartners =
  ({ data, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS
      });
      const { _ } = await api.falconDashboard.updatePayoutPartner(data);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.UPDATE_FALCON_CLIENT_PAYOUT_PARTNERS_FAILURE,
        payload: error
      });
    }
  };

export const getFalconTransactionFilterAndSearchComponents =
  () => async (dispatch) => {
    try {
      dispatch({
        type: types.GET_TRANSACTION_COMPONENTS
      });
      const { data } = await api.falconDashboard.getSearchConfigs(
        'transactions'
      );
      dispatch({
        type: types.GET_TRANSACTION_COMPONENTS_SUCCESS,
        payload: {
          filters: data.filters,
          searches: data.search,
          statusTransactionMapping: data?.configs?.statusCountList,
          configs: data?.configs
        }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const forceFailTransaction =
  ({ payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.FORCE_FAIL_TRANSACTION
      });

      const { data } = await api.falconDashboard.forceFailTransaction({
        payload
      });

      onSuccess && onSuccess(data);

      dispatch({
        type: types.FORCE_FAIL_TRANSACTION_SUCCESS
      });

      dispatch(
        setAlert({
          title: 'Force failed a transaction successfully!',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getForceStatusSync =
  ({ payoutId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.FORCE_SYNC_TRANSACTION // no corresponding receiver in reducer as of now for both dispatches
      });

      const { data } = await api.falconDashboard.forceStatusSync({ payoutId });

      onSuccess && onSuccess(data);

      dispatch({
        type: types.FORCE_SYNC_TRANSACTION_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const forceProcessTransaction =
  ({ payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.FORCE_PROCESS_TRANSACTION
      });

      const { data } = await api.falconDashboard.forceProcessTransaction({
        payload
      });

      onSuccess && onSuccess(data);

      dispatch({
        type: types.FORCE_PROCESS_TRANSACTION_SUCCESS
      });

      dispatch(
        setAlert({
          title: 'Force Processed a transaction successfully!',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

// Vendor Dashboard Actions
export const getVendors = (reqData, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_FALCON_VENDORS
    });
    const { data } = await api.falconDashboard.getVendors(reqData);
    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_FALCON_VENDORS_FAILURE,
      payload: error
    });
  }
};

export const getVendorDetails =
  ({ vendor, currency }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_FALCON_VENDOR_DETAILS
      });
      const { data } = await api.falconDashboard.getVendorDetails({
        vendor,
        currency
      });
      dispatch({
        type: types.GET_FALCON_VENDOR_DETAILS_SUCCESS,
        payload: { data }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_FALCON_VENDOR_DETAILS_FAILURE,
        payload: error
      });
    }
  };

export const getVendorFilters =
  ({ onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.falconDashboard.getSearchConfigs('vendors');
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const createVendor =
  ({ data, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CREATE_FALCON_VENDOR
      });
      const _ = await api.falconDashboard.createVendor(data);
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.CREATE_FALCON_VENDOR_FAILURE,
        payload: error
      });
    }
  };

export const updateVendor =
  ({ req, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_FALCON_VENDOR
      });
      const { data } = await api.falconDashboard.updateVendor(req);
      onSuccess && onSuccess();
      dispatch(
        getVendorDetails({ vendor: req.name, currency: req.currencyCode })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.UPDATE_FALCON_VENDOR_FAILURE,
        payload: error
      });
    } finally {
      onComplete && onComplete();
    }
  };

export const reEnableVendor =
  ({ formData, onComplete }) =>
  async (dispatch) => {
    try {
      await api.falconDashboard.reEnableVendor(formData);
      dispatch(
        setAlert({
          title: 'Vendor Re-enabled Successfullys',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const getDashboardConfig =
  ({ onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GET_DASHBOARD_CONFIG
      });
      const { data } = await api.falconDashboard.getSearchConfigs('dashboard');
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_DASHBOARD_CONFIG_FAILURE,
        payload: error
      });
    }
  };

// RFI Transaction Dashboard Actions
export const getRfiTransactions = (reqData, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_RFI_TRANSACTIONS
    });
    const { data } = await api.falconDashboard.getRfiTransactions(reqData);
    onSuccess && onSuccess(data);
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_RFI_TRANSACTIONS_FAILURE,
      payload: error
    });
  }
};

export const getRfiTransactionDetails = (requestID) => async (dispatch) => {
  try {
    dispatch({
      type: types.GET_RFI_TRANSACTION_DETAILS
    });
    const { data } = await api.falconDashboard.getRfiTransaction(requestID);
    dispatch({
      type: types.GET_RFI_TRANSACTION_DETAILS_SUCCESS,
      payload: { data }
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_RFI_TRANSACTION_DETAILS_FAILURE,
      payload: error
    });
  }
};

export const submitToPartner = (req) => async (dispatch) => {
  try {
    dispatch({
      type: types.SUBMITTED_TO_PARTNER
    });
    const { data } = await api.falconDashboard.submitToPartner(req);
    dispatch({
      type: types.SUBMITTED_TO_PARTNER_SUCCESS
    });
    dispatch(getRfiTransactionDetails(req.requestId));
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.SUBMITTED_TO_PARTNER_FAILURE,
      payload: error
    });
  }
};

export const rejectByPartner = (req) => async (dispatch) => {
  try {
    dispatch({
      type: types.REJECTED_BY_PARTNER
    });
    const { data } = await api.falconDashboard.rejectByPartner(req);
    dispatch({
      type: types.REJECTED_BY_PARTNER_SUCCESS
    });
    dispatch(getRfiTransactionDetails(req.requestId));
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.REJECTED_BY_PARTNER_FAILURE,
      payload: error
    });
  }
};

export const getRFIFilters =
  ({ onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.falconDashboard.getSearchConfigs('rfi');
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const submitRfiDocuments = (req) => async (dispatch) => {
  try {
    dispatch({
      type: types.SUBMIT_RFI_DOCUMENTS
    });
    const { data } = await api.falconDashboard.submitRfi(req);
    dispatch({
      type: types.SUBMIT_RFI_DOCUMENTS_SUCCESS,
      payload: { data }
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.SUBMIT_RFI_DOCUMENTS_FAILURE,
      payload: error
    });
  }
};
