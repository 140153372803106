import React, {useState} from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  Tab,
  TabList,
  Tabs,
  VStack,
  Spinner,
  Button,
  Divider,
  Box
} from '@chakra-ui/react';
import { TAB_STATES, TAB_STATES_ROLES, VENDORS_VIEW_MODE } from './constant';
import { ClientDashboard } from './ClientDashboard';
import { TransactionDashboard } from './TransactionDashboard';
import EntityDetails from '../../components/FalconServiceDashboard/EntityDetails';
import FalconTransactionCard from '../../components/FalconTransactionCard/FalconTransactionCard';
import { VendorDashboard } from './VendorDashboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  clientEntityConfig,
  rfiEntityConfig,
  vendorEntityConfig
} from './utils';
import { useEffect } from 'react';
import { FALCON_DASHBOARD_CONSTANTS } from '../../store/constants';
import { getDashboardConfig } from '../../store/actions/falconDashboardActions';
import useAuthRole from '../../hooks/useAuthRole';
import { RFITransactionDashboard } from './RFITransactionDashboard';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';


const FalconServiceDashboard = () => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();

  // Redux state
  const client = useSelector((state) => state.falconDashboard.client);
  const transactionsConfig = useSelector(
    (state) => state.falconDashboard.transactionDashboard.configs
  );
  const vendor = useSelector((state) => state.falconDashboard.vendor);
  const rfi = useSelector((state) => state.falconDashboard.rfiTransaction);

  // Local State
  const [selectedTab, setSelectedTab] = useState(TAB_STATES.TRANSACTIONS);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedRfiTransaction, setSelectedRfiTransaction] = useState(null);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const viewFromUrl = searchParams.get(VENDORS_VIEW_MODE.KEY);
    const isTableView = viewFromUrl === VENDORS_VIEW_MODE.TABLE;
    const isVendorTab = selectedTab === TAB_STATES.VENDORS;

    setShowRightSidebar(!(isTableView && isVendorTab));
  }, [selectedTab, searchParams]);

  useEffect(() => {
    dispatch(
      getDashboardConfig({
        onSuccess: (data) => {
          dispatch({
            type: FALCON_DASHBOARD_CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
            payload: { data: data.configs }
          });
          dispatch({
            type: FALCON_DASHBOARD_CONSTANTS.GET_TRANSACTION_CONFIGS_SUCCESS,
            payload: {
              payoutPartners: data.payoutPartners,
              statusList: data.statuses?.falcon
            }
          });
        }
      })
    );
  }, []);

  useEffect(() => {}, [renderTrigger]);

  const handleResize = (e) => {
    e?.preventDefault();
    if(showRightSidebar){
      setSelectedTransaction(null);
      setSelectedRfiTransaction(null);
      setSelectedVendor(null);
      setSelectedClient(null);
      setShowRightSidebar(false);
    } else {
      setShowRightSidebar(true);
    }
  }

  return (
    <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: `${showRightSidebar ? 7 : 10}fr 0fr ${showRightSidebar ? 3 : 0}fr` }}>
        {/* Main Column */}
        <VStack
          overflowY={'hidden'}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          pt={10}
          pb={4}
          px={{ base: 4, sm: 6, lg: 10 }}
          alignItems={'stretch'}
          gap={4}
        >
          <Heading size="lg" color="colorPrimary">
            Falcon Service Dashboard
          </Heading>
          <Tabs
            variant={'enclosed'}
            size={'md'}
            onChange={(e) => {
              setSelectedTab(Object.keys(TAB_STATES)[e]);
            }}
            defaultIndex={Object.keys(TAB_STATES).indexOf(selectedTab)}
          >
            <TabList color={'whiteAlpha.700'} borderColor={'whiteAlpha.500'}>
              {Object.keys(TAB_STATES).map(
                (tabState, ind) =>
                  hasAnyAuthority(...TAB_STATES_ROLES[tabState]) && (
                    <Tab
                      key={ind}
                      _selected={{
                        color: 'brand.500',
                        border: '1px solid',
                        borderColor: 'whiteAlpha.500',
                        borderBottom: '1px solid black'
                      }}
                      _focus={{
                        boxShadow: 'none'
                      }}
                    >
                      {kebabCaseToSpaceSeperate(tabState)}
                    </Tab>
                  )
              )}
            </TabList>
          </Tabs>

          {selectedTab === TAB_STATES.TRANSACTIONS && (
            <TransactionDashboard
              selectedTransaction={selectedTransaction}
              setSelectedTransaction={setSelectedTransaction}
            />
          )}
          {selectedTab === TAB_STATES.RFI_TRANSACTIONS && (
            <RFITransactionDashboard
              setSelectedRfiTrxs={setSelectedRfiTransaction}
              selectedTrx={selectedRfiTransaction}
            />
          )}
          {selectedTab === TAB_STATES.CLIENTS && (
            <ClientDashboard
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
            />
          )}
          {selectedTab === TAB_STATES.VENDORS && (
            <VendorDashboard
              setSelectedVendor={setSelectedVendor}
              selectedVendor={selectedVendor}
            />
          )}
        </VStack>

          <Box
            alignItems="center"
            position='relative'
          >
            <Divider
              style={{ margin: '0' }}
              borderColor="whiteAlpha.300"
              orientation="vertical"
              transition="width 0.3s ease-in-out"
            />
            <Button
              colorScheme="brand"
              size="sm"
              bg="black"
              border="2px solid"
              borderColor="whiteAlpha.500"
              borderRadius="xl"
              p={0}
              onClick={(e) => handleResize(e)}
              position={'absolute'}
              top={12}
              left={-4}
            >
              <Box
                as="span"
                transition="transform 0.3s ease-in-out"
                transform={!showRightSidebar ? "rotate(0deg)" : "rotate(180deg)"}
                display="inline-block"
              >
                <MdOutlineKeyboardDoubleArrowLeft />
              </Box>
            </Button>
          </Box>

        {/*Right Column*/}
          <VStack
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            pt={10}
            pb={4}
            px={{ base: 4, sm: 6, lg: 10 }}
            alignItems={'stretch'}
            gap={4}
          >
          {showRightSidebar && (
            <>
            {selectedTransaction?.transaction &&
              selectedTab === TAB_STATES.TRANSACTIONS &&
              (!selectedTransaction.isLoading ? (
                <FalconTransactionCard
                  falconTransaction={selectedTransaction.transaction}
                  recipient={selectedTransaction.recipient}
                  payout={selectedTransaction.payout}
                  isSidebar={true}
                  setRenderTrigger={setRenderTrigger}
                  configs={transactionsConfig}
                />
              ) : (
                <Spinner
                  align="center"
                  color="brand.400"
                  size="xl"
                  thickness="4px"
                />
              ))}

            {selectedRfiTransaction &&
              selectedTab === TAB_STATES.RFI_TRANSACTIONS && (
                <EntityDetails
                  isSidebar={true}
                  entity={selectedRfiTransaction}
                  client={rfi}
                  config={rfiEntityConfig}
                />
              )}

            {selectedClient && selectedTab === TAB_STATES.CLIENTS && (
              <EntityDetails
                isSidebar={true}
                entity={selectedClient}
                client={client}
                config={clientEntityConfig}
              />
            )}

            {selectedVendor && selectedTab === TAB_STATES.VENDORS && (
              <EntityDetails
                isSidebar={true}
                entity={selectedVendor}
                client={vendor}
                config={vendorEntityConfig}
              />
            )}
            </>
          )}
          </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default FalconServiceDashboard;
