import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spinner,
  StackDivider,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientFilters,
  getClients
} from '../../store/actions/falconDashboardActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';
import Pagination from '../../components/Pagination';
import { updateFalconClientReqData } from '../../store/actions/reqDataActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import CreateClientModal from '../../components/FalconServiceDashboard/CreateClientModal';
import useDebounce from '../../hooks/useDebounce';
import { clientEntityConfig } from './utils';
import SearchBox from '../../components/SearchBox/SearchBox';
import useAuthRole from '../../hooks/useAuthRole';
import { CARD_TYPE, ROLES } from '../../constants';
import GenericCard from '../../components/GenericCard';
import { FaArrowRightLong } from 'react-icons/fa6';
import {MdAdd} from "react-icons/md";


export const ClientDashboard = ({ setSelectedClient, selectedClient }) => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();

  // Redux state
  const clients = useSelector((state) => state.falconDashboard.clients);
  const configsLoading = useSelector(
    (state) => state.falconDashboard.config.isLoading
  );
  const reqData = useSelector((state) => state.reqData.falconDashboard.client);
  const [filterValue, setFilterValue] = useState({});
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });

  // Local State
  const debouncedValue = useDebounce(searchValue.searchValue, 500);
  const [selectedFilter, setSelectedFilter] = useState();

  const {
    isOpen: isCreateModalOpen,
    onClose: closeCreateModal,
    onOpen: openCreateModal
  } = useDisclosure();

  useEffect(() => {
    dispatch(
      getClientFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconClientReqData({
              searches: data.search,
              filterValues: data.filters
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconClientReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getClients({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_CLIENTS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconClientReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getClients({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_CLIENTS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconClientReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  const handlePageChange = (page) => {
    dispatch(
      updateFalconClientReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
  };

  return (
    <Box
      position={'relative'}
      overflowX={'hidden'}
      overflowY={'auto'}
    >
      <ModalLayout
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          onClose={closeCreateModal}
          config={clientEntityConfig}
        />
      </ModalLayout>
      <VStack
        overflowX="hidden"
        alignItems="stretch"
        gap={4}
        px={4}
        divider={<StackDivider borderColor="whiteAlpha.300" />}
      >
        <HStack justify={'space-between'}>
          <Heading size={"md"}> Clients </Heading>
          {!configsLoading &&
            hasAnyAuthority(
              ROLES.ADMIN_ROLE,
              ROLES.FALCON_ADMIN,
              ROLES.FALCON_OPS_MANAGER
            ) && (
              <Button
                  colorScheme={'brand'}
                  size="sm"
                  bg="backGround"
                  border={'2px solid'}
                  borderColor={'whiteAlpha.500'}
                  borderRadius={'lg'}
                  p={4}
                  onClick={openCreateModal}
                  leftIcon={<MdAdd size={'1.5rem'}/>}
              >
                Create a New Client
              </Button>
            )}
        </HStack>
        {/*Search*/}
        <HStack justify={'flex-start'}>
          {!reqData.isLoading && !reqData.isError > 0 && (
            reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )
          )}
        </HStack>
        {/*Pagination*/}
        {reqData?.totalPages > 1 && (
          <Pagination
            onPageChange={handlePageChange}
            currentPage={reqData.pageNumber + 1}
            totalPages={reqData.totalPages}
          />
        )}
        {/*Card Data*/}
        {clients.isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : clients.data?.length ? (
          <VStack alignItems="start" gap={4}>
            {clients.data.map((client, index) => (
              <GenericCard
                key={index}
                cardData={client}
                cardType={CARD_TYPE.FALCON_SERVICE_CLIENT_CARD}
                maxW={'100%'}
                selected={client===selectedClient}
                ActionButtons={[
                  <Button
                    colorScheme="brand"
                    color="black"
                    width="8rem"
                    size="sm"
                    fontSize="sm"
                    mt={3}
                    onClick={() => setSelectedClient(client)}
                    rightIcon={<FaArrowRightLong/>}
                  >
                    Details
                  </Button>,
                ]}
              />
            ))}
          </VStack>
        ) : (
            <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
              <Text color="white" fontSize="md">
                No Clients found
              </Text>
            </Box>
        )}
      </VStack>
    </Box>
  );
};
