import React, { useState } from 'react';
import {
  Flex,
  ModalBody,
  Button,
  ModalFooter,
  FormControl,
  ModalHeader,
  ModalCloseButton,
  Text
} from '@chakra-ui/react';
import DynamicFormField from '../../Config/DynamicFormField/DynamicFormField';
import { FORCE_COMPLETE_TRANSACTION_MODAL_FORM_CONFIG as formConfig } from './config';

const ForceProcessTransactionModal = ({
  title = 'Force Process Transaction',
  payoutPartnerName,
  isLoading = false,
  onSubmit,
  onClose
}) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === 'checkbox'
          ? checked
          : type === 'number'
          ? Number(value)
          : value
    }));
  };

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    onSubmit &&
      onSubmit({
        externalPayoutId: formData?.externalPayoutId,
        comment: formData?.comment
      });
  };

  return (
    <>
      <ModalHeader textAlign="center" py={0}>
        {title}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <FormControl as="form" onSubmit={handleLocalSubmit}>
          <Flex
            w="100%"
            justifyContent="flex-start"
            height="100%"
            alignItems="center"
          >
            <Text
              alignSelf="flex-end"
              fontSize="1rem"
              fontWeight="500"
              mb={0}
              lineHeight="2rem"
              pr={2}
            >
              Payout Partner: {payoutPartnerName}
            </Text>
          </Flex>
          {formConfig.map((field, index) => {
            if (field?.isEditable) {
              return (
                <DynamicFormField
                  key={`${index}-${field.value}`}
                  fieldConfig={field}
                  data={formData}
                  handleChange={handleChange}
                />
              );
            }
            return null;
          })}

          <ModalFooter justifyContent="center" gap={6}>
            <Button
              onClick={onClose}
              variant="ghost"
              colorScheme="white"
              bg="whiteAlpha.300"
              width="100%"
              size="md"
              fontSize="sm"
              mt={3}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="brand"
              color="altGray.400"
              width="100%"
              size="md"
              fontSize="sm"
              mt={3}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default ForceProcessTransactionModal;
