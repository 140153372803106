import dayjs from 'dayjs';
import {
  ALL_FUNNEL_KYC_STATUS,
  ALL_FUNNEL_ORDERS_STATUS,
  ALL_FUNNEL_V2_ORDERS_STATUS,
  ALL_LULU_ORDERS_FUNNEL_TABS,
  DASHBOARD_ACTIVE_SEARCH,
  FILTER_BY_FLAG,
  FILTER_BY_PAST_HOURS,
  JOB_FILTERS,
  NROB_ACTIVE_STEPS,
  NROB_FUNNEL_STATES,
  NROB_APPLICATION_STATES,
  NR_ACCOUNT_SEARCH_KEYS,
  VERIFICATIONS_ACTIVE_SEARCH,
  REWARD_TYPE,
  REWARD_STATUS,
  BENEFICIARY_FILTER_TYPE,
  BENEFICIARY_SEARCH_KEY_TYPE,
  LULU_REFUND_DASHBOARD_SEARCH_KEY,
  LULU_REFUND_DASHBOARD_FILTER, LULU_REFUND_DASHBOARD_SEARCH_FILTERS
} from '../../constants';
import {
  getDefaultKycProviderForProduct,
  getDefaultProduct
} from '../../utils';
import { VDA_FUNNEL_STATES } from '../../views/Funnel/SaberFunnel/constants';
import { REQ_DATA as types } from '../constants';
import rdaDashboard from '../../api/rdaDashboard';
import falconDashboard from '../../api/falconDashboard';

const initialState = {
  dashboard: {
    users: {
      query: '',
      pageNo: 0,
      pageSize: 10,
      kycFilter: '',
      countryCode: ''
    },
    orders: {
      query: '',
      orderType: '',
      pageNo: 0,
      pageSize: 10
    },
    luluOrders: {
      query: '',
      pageNo: 0,
      pageSize: 10
    },
    activeSearch: DASHBOARD_ACTIVE_SEARCH.users
  },
  user: {
    orders: {
      remittance: {
        pageNo: 0,
        pageSize: 10
      },
      investments: {
        pageNo: 0,
        pageSize: 10
      },
      sips: {
        pageNo: 0,
        pageSize: 10
      }
    },
    referrals: {
      pageNo: 0,
      pageSize: 10
    }
  },
  funnel: {
    ordersV2: {
      acquirer: '',
      orderType: '',
      ordersInPastHours: FILTER_BY_PAST_HOURS[0],
      activeFunnel: ALL_FUNNEL_V2_ORDERS_STATUS[0],
      funnelReqData: ALL_FUNNEL_V2_ORDERS_STATUS.reduce(
        (reqData, currStatus) => ({
          ...reqData,
          [currStatus]: {
            pageNo: 0,
            pageSize: 10
          }
        }),
        {}
      )
    },
    orders: {
      activeFunnel: ALL_FUNNEL_ORDERS_STATUS[0],
      orders: ALL_FUNNEL_ORDERS_STATUS.reduce(
        (prev, status) => ({
          ...prev,
          [status]: {
            filterByFlag: FILTER_BY_FLAG.UNFILTERED,
            filtered: {
              pageNo: 0,
              pageSize: 10
            },
            unfiltered: {
              pageNo: 0,
              pageSize: 10
            }
          }
        }),
        {}
      )
    },
    luluOrders: {
      activeTab: ALL_LULU_ORDERS_FUNNEL_TABS[0],
      orders: ALL_LULU_ORDERS_FUNNEL_TABS.reduce((prev, tab) => {
        prev[tab] = {
          pageNo: 0,
          pageSize: 10
        };
        return prev;
      }, {})
    },
    kyc: {
      activeFunnel: ALL_FUNNEL_KYC_STATUS[0],
      users: ALL_FUNNEL_KYC_STATUS.reduce(
        (prev, status) => ({
          ...prev,
          [status]: {
            pageNo: 0,
            pageSize: 10
          }
        }),
        {}
      )
    },
    /*
    {
      "page_no": 0,
      "page_size": 20,
      "query": null,
      "filters": [{
              "filter_key": "COUNTRY",
              "search_operation": "EQUALS",
              "values": [
                  "GB"
              ]
          }],
      "additional_filters": {
          "docs_pickup_pending_for_hours": null,
          "form_verification_pending_for_hours": null,
          "appointment_pending_for_hours": null
      }
    }
    */
    nronboard: {
      activeFunnel: Object.keys(NROB_ACTIVE_STEPS)[0],
      funnelSubState: '',
      country: '',
      initialized: false,
      reqData: Object.keys(NROB_ACTIVE_STEPS).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: {
            query: null,
            pageNo: 0,
            pageSize: 20,
            subState: '',
            additionalFilters: null
          }
        };
      }, {})
    },
    vdaFunnel: {
      page: 1,
      pageSize: 10,
      status: VDA_FUNNEL_STATES.ON_RAMP_INITIATED,
      search: ''
    }
  },
  referral: {
    campaigns: {
      pageNo: 0,
      pageSize: 10,
      query: ''
    },
    v2: {
      redeemedCoins: {
        pageNo: 0,
        pageSize: 10,
        status: ''
      },
      userRedeemedCoins: {
        pageNo: 0,
        pageSize: 10
      }
    }
  },
  nrAccount: {
    users: {
      pageNo: 0,
      pageSize: 10,
      query: '',
      filterKey: NR_ACCOUNT_SEARCH_KEYS.ACCOUNT_STATUS.displayText,
      filterValue: ''
    }
  },
  providerHolidays: {
    holidays: {
      pageNo: 0,
      pageSize: 10
    }
  },
  kyc: {
    user: {
      docs: {
        pageNo: 0,
        pageSize: 10
      },
      checks: {
        pageNo: 0,
        pageSize: 10,
        provider: getDefaultKycProviderForProduct(getDefaultProduct()),
        product: getDefaultProduct()
      }
    },
    kycs: {
      pageNo: 0,
      pageSize: 10,
      provider: getDefaultKycProviderForProduct(getDefaultProduct()),
      product: getDefaultProduct(),
      activeTab: 0,
      activeSearch: VERIFICATIONS_ACTIVE_SEARCH.REMITTANCE,
      status: '',
      query: '',
      country: ''
    }
  },
  investments: {
    orders: {
      pageNo: 0,
      pageSize: 10,
      query: '',
      filterKey: '',
      filterValue: ''
    }
  },
  thunes: {
    orders: {
      pageNo: 0,
      pageSize: 10
    },
    fulfillments: {
      pageNo: 0,
      pageSize: 10,
      orderId: ''
    }
  },
  sourceAccount: {
    accounts: {
      pageNo: 0,
      pageSize: 10
    }
  },
  jobs: {
    pageNo: 0,
    pageSize: 10,
    filters: Object.keys(JOB_FILTERS)
      .map((filter) => ({
        key: JOB_FILTERS[filter].FILTER_KEY,
        value: ''
      }))
      .reduce(
        (prev, filter) => ({
          ...prev,
          [filter.key]: filter.value
        }),
        {}
      )
  },
  tickets: {
    query: '',
    pageNo: 0,
    pageSize: 10,
    status: '',
    assignedTo: ''
  },
  userSourceAccount: {
    page: 0,
    pageSize: 10
  },
  rda: {
    page: 1,
    pageSize: 10,
    active: false
  },
  vanceCash: {
    page: 1,
    pageSize: 10,
    totalPages: 0
  },
  uaeManualNonReconciled: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    orderId: '',
    isUpdatedByAgent: ''
  },
  pendingOrderReceipts: {
    pageNumber: 0,
    pageSize: 10,
    orderId: '',
    totalPages: 0,
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
    receiptStatus: 'REVIEW_PENDING'
  },
  // Adding query here to add search feature
  rewards: {
    query: '',
    pageNumber: 0,
    pageSize: 10,
    rewardType: REWARD_TYPE.GOLD_REWARD,
    rewardStatus: REWARD_STATUS.SUBMITTED,
    totalPages: 1
  },
  baerscrestOps: {
    pageNumber: 0,
    pageSize: 10,
    orderId: '',
    status: '',
    fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
    totalPages: 1
  },
  rdaDashboard: {
    overall: {
      pageNumber: 0,
      pageSize: 10,
      orderId: '',
      status: '',
      fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD')
    },
    truelayer: {
      pageNumber: 0,
      pageSize: 10,
      fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD')
    },
    clearbank: {
      pageNumber: 0,
      pageSize: 10,
      fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
      transactionType: 'COMPLETED'
    },
    ybl: {
      pageNumber: 0,
      pageSize: 10,
      fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD')
    }
  },
  falconDashboard: {
    client: {
      pageNumber: 0,
      pageSize: 20,
      sortKey: 'updatedAt',
      sortOrder: 'DESC',
      filters: null,
      filterValues: null,
      searchKey: null,
      searchValue: null,
      searches: null,
    },
    transactions: {
      pageNumber: 0,
      pageSize: 10
    },
    vendor: {
      pageNumber: 0,
      pageSize: 20,
      sortKey: 'clientBalance',
      sortOrder: 'DESC',
      filters: null,
      filterValues: null,
      searchKey: null,
      searchValue: null,
      searches: null
    },
    rfiTransactions:{
      pageNumber: 0,
      pageSize: 20,
      sortKey: 'updatedAt',
      sortOrder: 'DESC',
      filters: null,
      filterValues: null,
      searchKey: null,
      searchValue: null,
      searches: null
    }
  },
  beneficiaryVerificationDashboard: {
    searchKey: BENEFICIARY_SEARCH_KEY_TYPE.ACCOUNT_NUMBER,
    searchValue: '',
    filterType: BENEFICIARY_FILTER_TYPE.ALL_DATA,
    pageNumber: 0,
    pageSize: 10,
    totalPages: 1
  },
  luluRefundsDashboard: {
    searchTransaction: {
      key: LULU_REFUND_DASHBOARD_SEARCH_KEY.TRANSACTION_ID,
      value: ''
    },
    searchSheet: {
      key: LULU_REFUND_DASHBOARD_SEARCH_KEY.SHEET_ID,
      value: ''
    },
    filter: {
      key: LULU_REFUND_DASHBOARD_FILTER.REFUND_STATUS,
      value: LULU_REFUND_DASHBOARD_SEARCH_FILTERS[LULU_REFUND_DASHBOARD_FILTER.REFUND_STATUS].filters,
    },
    timestampFilter: {
      startDate: '',
      endDate: ''
    },
    sort: true,
    page: 1,
    pageSize: 10,
    totalPages: 1,
  }
};

const reqDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_USERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          users: {
            ...state.dashboard.users,
            ...payload
          }
        }
      };

    case types.UPDATE_ORDERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          orders: {
            ...state.dashboard.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_LULU_ORDERS_REQ_DATA:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          luluOrders: {
            ...state.dashboard.luluOrders,
            ...payload
          }
        }
      };

    case types.UPDATE_ACTIVE_SEARCH:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          activeSearch: payload
        }
      };

    case types.UPDATE_ACTIVE_ORDERS_FUNNEL:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            activeFunnel: payload
          }
        }
      };

    case types.UPDATE_ACTIVE_LULU_ORDERS_TAB:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          luluOrders: {
            ...state.funnel.luluOrders,
            activeTab: payload
          }
        }
      };

    case types.UPDATE_ACTIVE_KYC_USERS_FUNNEL:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          kyc: {
            ...state.funnel.kyc,
            activeFunnel: payload
          }
        }
      };

    case types.UPDATE_FUNNEL_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            orders: {
              ...state.funnel.orders.orders,
              [payload.status]: {
                ...state.funnel.orders.orders[payload.status],
                [state.funnel.orders.orders[payload.status].filterByFlag]: {
                  ...state.funnel.orders.orders[payload.status][
                    state.funnel.orders.orders[payload.status].filterByFlag
                  ],
                  ...payload.reqData
                }
              }
            }
          }
        }
      };

    case types.UPDATE_LULU_FUNNEL_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          luluOrders: {
            ...state.funnel.luluOrders,
            orders: {
              ...state.funnel.luluOrders.orders,
              [payload.tab]: {
                ...state.funnel.luluOrders.orders[payload.tab],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_USERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          kyc: {
            ...state.funnel.kyc,
            users: {
              ...state.funnel.kyc.users,
              [payload.status]: {
                ...state.funnel.kyc.users[payload.status],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_FLAGGED_FILTER:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          orders: {
            ...state.funnel.orders,
            orders: {
              ...state.funnel.orders.orders,
              [payload.status]: {
                ...state.funnel.orders.orders[payload.status],
                filterByFlag: payload.filterByFlag
              }
            }
          }
        }
      };

    case types.UPDATE_CAMPAIGNS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          campaigns: {
            ...state.referral.campaigns,
            ...payload
          }
        }
      };

    case types.UPDATE_NR_USERS_REQ_DATA:
      return {
        ...state,
        nrAccount: {
          ...state.nrAccount,
          users: {
            ...state.nrAccount.users,
            ...payload
          }
        }
      };

    case types.UPDATE_PROVIDER_HOLIDAYS_REQ_DATA:
      return {
        ...state,
        providerHolidays: {
          ...state.providerHolidays,
          holidays: {
            ...state.providerHolidays.holidays,
            ...payload
          }
        }
      };

    case types.UPDATE_KYC_DOCS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            docs: {
              ...state.kyc.user.docs,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_KYC_CHECKS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            checks: {
              ...state.kyc.user.checks,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_KYC_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          user: {
            ...state.kyc.user,
            kyc: {
              ...state.kyc.user.kyc,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_ALL_KYCS_REQ_DATA:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          kycs: {
            ...state.kyc.kycs,
            ...payload
          }
        }
      };

    case types.UPDATE_INVESTMENT_ORDERS_REQ_DATA:
      return {
        ...state,
        investments: {
          ...state.investments,
          orders: {
            ...state.investments.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_TRANSFERS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            remittance: {
              ...state.user.orders.remittance,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_REFERRAL_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          referrals: {
            ...state.user.referrals,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_INVESTMENT_ORDERS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            investments: {
              ...state.user.orders.investments,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_SIPS_REQ_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          orders: {
            ...state.user.orders,
            sips: {
              ...state.user.orders.sips,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_THUNES_ORDERS_REQ_DATA:
      return {
        ...state,
        thunes: {
          ...state.thunes,
          orders: {
            ...state.thunes.orders,
            ...payload
          }
        }
      };

    case types.UPDATE_THUNES_FULFILLMENTS_REQ_DATA:
      return {
        ...state,
        thunes: {
          ...state.thunes,
          fulfillments: {
            ...state.thunes.fulfillments,
            ...payload
          }
        }
      };

    case types.UPDATE_SOURCE_ACCOUNTS_REQ_DATA:
      return {
        ...state,
        sourceAccount: {
          ...state.sourceAccount,
          accounts: {
            ...state.sourceAccount.accounts,
            ...payload
          }
        }
      };

    case types.UPDATE_JOBS_REQ_DATA:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          ...payload
        }
      };

    case types.UPDATE_REDEEMED_COINS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          v2: {
            ...state.referral.v2,
            redeemedCoins: {
              ...state.referral.v2.redeemedCoins,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_USER_REDEEMED_COINS_REQ_DATA:
      return {
        ...state,
        referral: {
          ...state.referral,
          v2: {
            ...state.referral.v2,
            userRedeemedCoins: {
              ...state.referral.v2.userRedeemedCoins,
              ...payload
            }
          }
        }
      };

    case types.UPDATE_FUNNEL_V2_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          ordersV2: {
            ...state.funnel.ordersV2,
            ...payload
          }
        }
      };

    case types.UPDATE_FUNNEL_V2_ORDERS_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state.funnel,
          ordersV2: {
            ...state.funnel.ordersV2,
            funnelReqData: {
              ...state.funnel.ordersV2.funnelReqData,
              [payload.status]: {
                ...state.funnel.ordersV2.funnelReqData[payload.status],
                ...payload.reqData
              }
            }
          }
        }
      };

    case types.UPDATE_TICKETS_REQ_DATA:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ...payload
        }
      };

    case types.UPDATE_NROB_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state?.funnel,
          nronboard: {
            ...state?.funnel?.nronboard,
            ...payload
          }
        }
      };

    case types.UPDATE_USER_SOURCE_ACCOUNTS_REQ_DATA:
      return {
        ...state,
        userSourceAccount: {
          ...state?.userSourceAccount,
          ...payload
        }
      };

    case types.UPDATE_RDA_REQ_DATA:
      return {
        ...state,
        rda: {
          ...state?.rda,
          ...payload
        }
      };

    case types.UPDATE_VANCE_CASH_REQ_DATA:
      return {
        ...state,
        vanceCash: {
          ...state?.vanceCash,
          ...payload
        }
      };

    case types.UPDATE_VDA_FUNNEL_REQ_DATA:
      return {
        ...state,
        funnel: {
          ...state?.funnel,
          vdaFunnel: {
            ...state?.funnel?.vdaFunnel,
            ...payload
          }
        }
      };

    case types.UPDATE_UAE_MANUAL_NON_RECONCILED:
      return {
        ...state,
        uaeManualNonReconciled: {
          ...state?.uaeManualNonReconciled,
          ...payload
        }
      };

    case types.UPDATE_PENDING_ORDER_RECEIPTS_REQ_DATA:
      return {
        ...state,
        pendingOrderReceipts: {
          ...state?.pendingOrderReceipts,
          ...payload
        }
      };

    case types.UPDATE_REWARDS_REQ_DATA:
      return {
        ...state,
        rewards: {
          ...state?.rewards,
          ...payload
        }
      };

    case types.UPDATE_BAERSCREST_OPS_REQ_DATA:
      return {
        ...state,
        baerscrestOps: {
          ...state?.baerscrestOps,
          ...payload
        }
      };

    case types.UPDATE_RDA_DASHBOARD_REQ_DATA:
      return {
        ...state,
        rdaDashboard: {
          ...state?.rdaDashboard,
          ...payload
        }
      };

    case types.UPDATE_FALCON_CLIENT_REQ_DATA:
      return {
        ...state,
        falconDashboard: {
          ...state.falconDashboard,
          client: {
            ...state.falconDashboard.client,
            ...payload
          }
        }
      };

    case types.UPDATE_FALCON_TRANSACTIONS_REQ_DATA:
      return {
        ...state,
        falconDashboard: {
          ...state.falconDashboard,
          transactions: {
            ...state.falconDashboard.transactions,
            ...payload
          }
        }
      };

    case types.UPDATE_FALCON_VENDOR_REQ_DATA:
      return {
        ...state,
        falconDashboard: {
          ...state.falconDashboard,
          vendor: {
            ...state.falconDashboard.vendor,
            ...payload
          }
        }
      };
    case types.UPDATE_FALCON_RFI_TRANSACTIONS_REQ_DATA:
      return {
        ...state,
        falconDashboard: {
          ...state.falconDashboard,
          rfiTransactions: {
            ...state.falconDashboard.rfiTransactions,
            ...payload
          }
        }
      };

    case types.UPDATE_BENEFICIARY_VERIFICATION_REQ_DATA:
      return {
        ...state,
        beneficiaryVerificationDashboard: {
          ...state?.beneficiaryVerificationDashboard,
          ...payload
        }
      };

    case types.UPDATE_LULU_ORDER_REFUND_REQ_DATA:
      return {
      ...state,
      luluRefundsDashboard: {
        ...state?.luluRefundsDashboard,
        ...payload
      }
    };

    default:
      return state;
  }
};

export default reqDataReducer;
