import React, {useState, useEffect, useMemo} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Select,
  List,
  ListItem,
  Flex,
  IconButton,
  Text, FormLabel, HStack, VStack
} from '@chakra-ui/react';
import {
  MdDeleteOutline,
  MdDragIndicator,
  MdOutlineAdd,
} from 'react-icons/md';


function ClientManager({ listOfAvailableClients, setFormData }) {
  const [selectedClient, setSelectedClient] = useState('');
  const [addedClients, setAddedClients] = useState([]);

  // Handle adding a client
  const addClient = () => {
    if (selectedClient && !addedClients.includes(selectedClient)) {
      setAddedClients([...addedClients, selectedClient]);
      setSelectedClient(''); // Reset the dropdown
    }
  };

  // Handle deleting a client
  const deleteClient = (client) => {
    setAddedClients(addedClients.filter((c) => c !== client));
  };

  // Handle drag-and-drop reordering
  const handleDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list, do nothing

    const items = Array.from(addedClients);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddedClients(items);
  };

  const memoizedClients = useMemo(() => addedClients, [addedClients]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
        clientPriorityList: addedClients
    }));
  }, [memoizedClients]);

  return (
    <Box>
      <VStack spacing={0} mb={4}>
        <Flex w="100%" justifyContent="flex-start" height="100%" alignItems="center">
          <FormLabel
            alignSelf="flex-end"
            fontSize="0.75rem"
            fontWeight="500"
            mb={0}
            lineHeight="2rem"
            pr={2}
            size={'md'}
            htmlFor={'clientPriorityList'}
          >
            Force Routing Partners List
          </FormLabel>
        </Flex>
        <HStack gap={2}>
          <Select
            placeholder="Select a client"
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            {listOfAvailableClients.map((client, index) => (
              <option key={index} value={client}>
                {client}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="brand"
            size={'md'}
            onClick={addClient}
            isDisabled={!selectedClient}
            rightIcon={<MdOutlineAdd />}
          >
            Add
          </Button>
        </HStack>
      </VStack>
      {/* Drag-and-Drop List */}
      <Box
        borderRadius="10px"
        border="1px solid"
        borderColor="whiteAlpha.300"
        maxHeight="24rem"
        overflow="scroll"
        p={4}
      >
        {addedClients.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="clients-list">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={2}
                >
                  {addedClients.map((client, index) => (
                    <Draggable key={client} draggableId={client} index={index}>
                      {(provided) => (
                        <ListItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          border="1px solid"
                          borderColor="whiteAlpha.300"
                          borderRadius="xl"
                          bgColor={'whiteAlpha.300'}
                          p={2}
                        >
                          <HStack>
                            <MdDragIndicator size={'1.25rem'}/>
                            <Text>
                              {index + 1}. {client}
                            </Text>
                          </HStack>
                          <IconButton
                            icon={<MdDeleteOutline />}
                            colorScheme={"red"}
                            size="sm"
                            bgColor='whiteAlpha.300'
                            border="1px solid"
                            borderColor="whiteAlpha.300"
                            borderRadius="full"
                            p={0}
                            onClick={() => deleteClient(client)}
                            aria-label={`Delete ${client}`}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  item - {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Text color="yellow.500" size="sm" align="center">
            No Routing Partners Selected.
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default ClientManager;
