import {Text, VStack} from "@chakra-ui/react";
import React from "react";


const StatusCard = ({
    status,
    onClick,
    isActive,
    activeColor
}) => (
    <VStack
        minWidth="5rem"
        minHeight="5rem"
        p={2}
        bg={"black"}
        alignItems="stretch"
        justifyContent="center"
        border={isActive ? "1px solid" : "1px solid"}
        borderRadius="xl"
        borderColor={isActive ? activeColor : "whiteAlpha.300"}
        cursor="pointer"
        _hover={{ bgColor: isActive ? activeColor : "#414141" }}
        boxShadow={isActive ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none"}
        spacing={0}
        onClick={() => onClick(status.status)}
    >
        <Text fontSize="2xl" fontWeight="bold">{status.count}</Text>
        <Text fontSize="xs">{status.title}</Text>
    </VStack>
);

export default StatusCard;