import React, {useCallback, useEffect, useState} from 'react';
import {Box, Flex, Heading, Spinner, StackDivider, Text, VStack} from '@chakra-ui/react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getFalconTransactionDetails,
  getFalconTransactionFilterAndSearchComponents,
  getTransactions,
  updateFalconTransactionDashboardRequestBody
} from '../../store/actions/falconDashboardActions';
import FalconTransactionCard from '../../components/FalconTransactionCard/FalconTransactionCard';
import FilterSelect from '../../components/FiltersBox/FilterSelect';
import SearchBox from '../../components/SearchBox/SearchBox';
import Pagination from '../../components/Pagination';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from "../../hooks/useDidMount";
import {
  TRANSACTIONS_FILTER_TYPES,
  FALCON_TRANSACTION_DEFAULT_FILTERS,
  FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST,
  FALCON_TRANSACTION_EXPANDED_DISPLAY_STATUS_COUNT_LIST
} from "./constant";
import { mapStatusCountListToObj } from "./utils";
import { getFalconTxnStatusColor } from "../../components/FalconTransactionCard/utils";
import StatusCount from "../../components/FalconServiceDashboard/StatusCount/StatusCount";


export const TransactionDashboard = ({
  selectedTransaction,
  setSelectedTransaction
}) => {
  const dispatch = useDispatch();

  // Redux State Mgmt
  const {
    data: transactions,
    isLoading,
    requestBody,
    totalPages,
    transactionDetails,
    searches,
    filters,
    componentsLoading,
    configs,
    statusTransactionMapping
  } = useSelector((state) => state.falconDashboard.transactionDashboard);

  const {
    transaction,
    recipient,
    payout,
    isLoading: isDetailsLoading
  } = transactionDetails;

  // Local State Management
  const [searchParams, setSearchParams] = useState({});
  const [filterParams, setFilterParams] = useState(FALCON_TRANSACTION_DEFAULT_FILTERS || {});
  const debouncedSearchParams = useDebounce(searchParams, 500);
  const debouncedFilterParams = useDebounce(filterParams, 500);

  // Fetch Transactions
  const fetchTransactions = () => {
    const combinedRequestBody = {
      ...requestBody,
      ...debouncedSearchParams,
      filters: { ...debouncedFilterParams }
    };
    dispatch(
      getTransactions({
        requestBody: combinedRequestBody
      })
    );
  };

  // Update Selected Transaction Details
  useEffect(() => {
    if (isDetailsLoading) {
      setSelectedTransaction({
        isLoading: isDetailsLoading
      });
    } else if (transaction) {
      setSelectedTransaction({
        transaction,
        recipient,
        payout,
        isDetailsLoading
      });
    }
  }, [transaction, recipient, payout, isDetailsLoading]);

  // Fetch Filters and Searches
  useEffect(() => {
    dispatch(getFalconTransactionFilterAndSearchComponents());
  }, [dispatch]);

  // Fetch Transactions when Filters or Searches Update - Default Filters
  useDidMountEffect(() => {
      fetchTransactions();
  }, [
    debouncedSearchParams,
    debouncedFilterParams,
    requestBody.pageNumber,
  ]);

  // Handle Search Change
  const handleSearchChange = useCallback((search) => {
    setSearchParams((prev) => ({
      ...prev,
      searchKey: search.searchKey,
      searchValue: search.searchValue,
    }));
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        searchKey: search.searchKey,
        searchValue: search.searchValue,
        pageNumber: 0,
      })
    );
  }, [dispatch, requestBody, searchParams]);

  // Handle Filter Change
  const handleFilterChange = ({ key, filters }) => {
    setFilterParams((prev) => {
      const updatedParams = { ...prev };
      if (filters.length === 0) {
        delete updatedParams[key];
      } else {
        updatedParams[key] = filters;
      }
      return updatedParams;
    });
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        filters: {
          ...requestBody.filters,
          [key]: filters
        },
        pageNumber: 0
      })
    );
  };

  // Handle Page Change
  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page - 1);
    dispatch(
      updateFalconTransactionDashboardRequestBody({
        ...requestBody,
        pageNumber: newPageNumber
      })
    );
  };

  // Handle Transaction Selection
  const handleSelectTransaction = (details) => {
    dispatch(
      getFalconTransactionDetails({
        selectedTransaction: details,
        transactionId: details.transactionId,
        recipientId: details.recipientId
      })
    );
    setSelectedTransaction({});
  };

  const fetchDefaultStatusList = () => {
    return mapStatusCountListToObj(statusTransactionMapping, FALCON_TRANSACTION_DEFAULT_DISPLAY_STATUS_COUNT_LIST);
  }

  const fetchExpandedStatusList = () => {
    return mapStatusCountListToObj(statusTransactionMapping, FALCON_TRANSACTION_EXPANDED_DISPLAY_STATUS_COUNT_LIST);
  }

  return (
    <VStack
      overflowY={{ base: 'initial', lg: 'scroll' }}
      overflowX="hidden"
      alignItems="stretch"
      gap={4}
      px={4}
      divider={<StackDivider borderColor="whiteAlpha.300" />}
    >
      <Heading size={"md"}> Transactions </Heading>

      <StatusCount
          fetchDefaultStatusList = {fetchDefaultStatusList}
          fetchExpandedStatusList = {fetchExpandedStatusList}
          defaultActiveStatusList = {FALCON_TRANSACTION_DEFAULT_FILTERS[TRANSACTIONS_FILTER_TYPES.STATUS]}
          onStatusClick = {(updatedStatus) => handleFilterChange({
            key: TRANSACTIONS_FILTER_TYPES.STATUS,
            filters: updatedStatus
          })}
          fetchColorByStatus = {getFalconTxnStatusColor}
      />

      <VStack
        justifyContent={'stretch'}
        alignItems="start"
        gap={2}
      >
        {!componentsLoading ? (
            Array.isArray(searches) && (
              <VStack
                justifyContent={'stretch'}
                alignItems="start"
                gap={2}>
                <SearchBox
                  searches={searches}
                  handleSearchChange={handleSearchChange}
                />
                <FilterSelect
                  handleFilterSubmit={handleFilterChange}
                  filtersData={filters}
                  defaultFiltersData={FALCON_TRANSACTION_DEFAULT_FILTERS}
                />
              </VStack>
            )
          ) : (
            <Flex justifyContent="center" alignItems="center" w="full" h="full">
              <Spinner thickness="4px" size="lg" color="colorPrimary" />
            </Flex>
          )
        }
      </VStack>

      {totalPages > 1 && (
        <Pagination
          currentPage={requestBody.pageNumber + 1}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      )}

      {componentsLoading || isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="full" h="full">
          <Spinner thickness="4px" size="lg" color="colorPrimary" />
        </Flex>
      ) : transactions?.length ? (
        <VStack alignItems="start" gap={4}>
          {transactions.map((details, index) => (
            <FalconTransactionCard
              key={index}
              falconTransaction={details}
              onOrderDetailsClick={() => {
                handleSelectTransaction(details);
              }}
              configs={configs}
              selected={selectedTransaction?.transaction === details}
              maxW = {'100%'}
            />
          ))}
        </VStack>
      ) : (
          <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Text color="white" fontSize="md">
              No Transactions found
            </Text>
          </Box>
      )}
    </VStack>
  );
};
