import { FORCE_FAIL_TRANSACTION_COMMENT_TYPE_CONFIG } from '../../../views/FalconServiceDashboard/constant';

export const FORCE_FAIL_TRANSACTION_MODAL_FORM_CONFIG = [
  {
    value: 'comment',
    name: 'comment',
    label: 'Comment',
    caption: null,
    type: 'select',
    size: 'md',
    placeholder: 'Select a Comment Type',
    options: FORCE_FAIL_TRANSACTION_COMMENT_TYPE_CONFIG?.map((type) => {
      return {
        value: type.value,
        key: type.key,
        label: type.label,
        isDisabled: type.isDisabled
      };
    }),
    isEditable: true,
    isRequired: true
  }
];
