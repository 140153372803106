import React, { useState } from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormControl,
  ModalCloseButton,
  Text,
  Flex
} from '@chakra-ui/react';
import DynamicFormField from "../../Config/DynamicFormField/DynamicFormField";
import ClientManager from "../ClientManager";
import { FORCE_FAIL_TRANSACTION_MODAL_FORM_CONFIG as formConfig} from "./config";


const ForceFailTransactionModal = ({
  isLoading,
  onCancel,
  onSubmit,
  payoutPartnerName,
  title = 'Force Fail a Transaction',
  listOfAvailableClients = []
}) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value,
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit &&
      onSubmit({
        comment: formData?.comment,
        forceRoutingPartners: formData?.clientPriorityList
      });
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    onCancel && onCancel();
  };

  return (
    <>
      <ModalHeader textAlign="center" py={0}>
        {title}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as="form" onSubmit={handleSubmit}>
          <Flex
            w="100%"
            justifyContent="flex-start"
            height="100%"
            alignItems="center"
          >
            <Text
              alignSelf="flex-end"
              fontSize="1rem"
              fontWeight="500"
              mb={0}
              lineHeight="2rem"
              pr={2}
            >
              Payout Partner : {payoutPartnerName}
            </Text>
          </Flex>
            {formConfig && formConfig?.map((field, index) => {
              if (field?.isEditable) {
                return (
                  <DynamicFormField
                    key={`${index}-${field.value}`}
                    fieldConfig={field}
                    data={formData}
                    handleChange={handleChange}
                  />
                );
              }
              return null;
            })}
            <ClientManager
              setFormData={setFormData}
              listOfAvailableClients={listOfAvailableClients}
            />
            <ModalFooter justifyContent="center" gap={6}>
              <Button
                onClick={handleCancel}
                variant="ghost"
                colorScheme="white"
                bg="whiteAlpha.300"
                width="100%"
                size="md"
                fontSize="sm"
                mt={3}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                color="altGray.400"
                width="100%"
                size="md"
                fontSize="sm"
                mt={3}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          </FormControl>
        </ModalBody>
    </>
  );
};

export default ForceFailTransactionModal;