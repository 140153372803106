export const FORCE_COMPLETE_TRANSACTION_MODAL_FORM_CONFIG = [
  {
    value: 'externalPayoutId',
    name: 'externalPayoutId',
    label: 'External Payout Id',
    placeholder: 'Enter External Id here...',
    type: 'input',
    inputType: 'string',
    size: 'md',
    isEditable: true,
    isRequired: true,
    maxLength: 32
  },
  {
    value: 'comment',
    name: 'comment',
    label: 'Comment',
    placeholder: 'Enter a comment here...',
    type: 'textarea',
    size: 'md',
    isEditable: true,
    isRequired: true
  }
];
