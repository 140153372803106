import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
} from '@chakra-ui/react';

const ReusableModal = ({ config }) => {
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    config.fields.forEach((field) => {
      initialData[field.title] =
        field.type === 'switch' &&
        (field.default || field.options?.[0]?.value || false);
    });
    return initialData;
  });

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Modal isOpen={config.isOpen} onClose={config.onClose}>
      <ModalOverlay />
      <ModalContent bg="backGround" color="white.10" fontFamily="body">
        <ModalHeader fontFamily="heading" color="colorPrimary">
          {config.title}
        </ModalHeader>
        <ModalBody>
          {config.fields.map((field, index) => (
            <FormControl key={index} mt={4}>
              <FormLabel color="shadedGray.100">{field.label}</FormLabel>
              {field.type === 'text' && (
                <Input
                  placeholder={field.placeholder}
                  bg="cardColor.100"
                  borderColor="shadedGray.200"
                  color="white.10"
                  onChange={(e) => handleChange(field.title, e.target.value)}
                />
              )}
              {field.type === 'select' && (
                <Select
                  bg="cardColor.100"
                  borderColor="shadedGray.200"
                  color="white.10"
                  onChange={(e) => handleChange(field.title, e.target.value)}
                >
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )}
              {field.type === 'switch' && (
                <Switch
                  colorScheme="green"
                  onChange={(e) => handleChange(field.title, e.target.checked)}
                  defaultChecked={field.default || false}
                />
              )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            bg="colorPrimary"
            color="backGround"
            mr={3}
            onClick={() => config.onSubmit(formData)}
            isLoading={config.isLoading}
          >
            {config.submitText || 'Submit'}
          </Button>
          <Button
            variant="ghost"
            color="shadedGray.100"
            onClick={config.onClose}
            isDisabled={config.isLoading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReusableModal;
