import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  VStack,
  Spinner,
  Text,
  Flex,
  Button,
  HStack,
  FormLabel,
  StackDivider,
  Heading
} from '@chakra-ui/react';

import { MdSearch } from 'react-icons/md';
import useDidMountEffect from '../../../hooks/useDidMount';
import { getLuluRefunds } from '../../../store/actions/luluRefundsActions';
import { updateLuluRefundDashboardReqData } from '../../../store/actions/reqDataActions';
import {
  CARD_TYPE,
  LULU_REFUND_DASHBOARD_SEARCH_FILTERS,
} from '../../../constants';
import InputComponent from '../../Input';
import Pagination from '../../Pagination';
import GenericCard from '../../GenericCard';
import FilterSelect from '../../FiltersBox/FilterSelect';
import dayjs from 'dayjs';

const ViewRefunds = ({
  selectedTransaction,
  setSelectedTransaction,
}) => {
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  const searchReqData = useSelector((state) => state?.reqData?.luluRefundsDashboard);
  const {
    searchSheet,
    searchTransaction,
    filter: statusFilter,
    timestampFilter,
    page: pageNumber,
    totalPages
  } = searchReqData;
  const { value: transactionValue } = searchTransaction;
  const { value: sheetValue } = searchSheet;
  const { startDate, endDate} = timestampFilter;
  const {
    data: luluRefundList,
    isLoading,
    isError,
    error,
  } = useSelector((state) => state?.luluRefundsDashboard?.luluTransactions);

  // Re-fetch the data on change in sheetValue and transactionValue
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      searchLuluRefunds(true);
    }, 2000);

    return () => {
      if(searchTimer.current)
        clearTimeout(searchTimer.current);
    };
  }, [searchTransaction, searchSheet]);

  // Re-fetch the data on change in filters
  useDidMountEffect(() => {
    searchLuluRefunds(true);
  }, [statusFilter, pageNumber, timestampFilter]);

  // Fetch the data for the first time
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      getLuluRefunds({
        payload: {
          ...searchReqData
        },
        onSuccess: (refundData) => {
          const { totalPages, page, pageSize } = refundData;
          dispatch(
            updateLuluRefundDashboardReqData({
              ...searchReqData,
              page: page,
              pageSize: pageSize,
              totalPages: totalPages,
            })
          );
        },
      })
    );
  };

  const handleTimestampDataChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    dispatch(
      updateLuluRefundDashboardReqData({
        ...searchReqData,
        timestampFilter: {
          ...searchReqData.timestampFilter,
          [key]: value
        },
        page: 1,
      })
    );
  };

  const handleSearchTransactionReqDataChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value === transactionValue) return;
      dispatch(
        updateLuluRefundDashboardReqData({
          ...searchReqData,
          searchTransaction:{
            ...searchReqData.searchTransaction,
            value: value,
          },
          page: 1,
        })
      );
    },
    [dispatch, searchReqData, transactionValue]
  );

  const handleSearchRefundSheetReqDataChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value === sheetValue) return;
      dispatch(
        updateLuluRefundDashboardReqData({
          ...searchReqData,
          searchSheet:{
            ...searchReqData.searchSheet,
            value: value,
          },
          page: 1,
        })
      );
    },
    [dispatch, searchReqData, sheetValue]
  );


  const handlePageChange = (page) => {
    const newPageNumber = Math.max(0, page);
    dispatch(
      updateLuluRefundDashboardReqData({
        ...searchReqData,
        page: newPageNumber,
      })
    );
  };

  const handleFilterSubmit = ({ key, filters }) => {
    dispatch(
      updateLuluRefundDashboardReqData({
        ...searchReqData,
        filter: {
          ...searchReqData.filter,
          key: key,
          value: filters,
        },
      })
    );
  }

  const searchLuluRefunds = async (refresh = false) => {
    if (refresh || !luluRefundList?.length) {
      fetchData();
    }
  };

  return (
        <VStack
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX="hidden"
          alignItems="stretch"
          gap={4}
          px={4}
          divider={<StackDivider borderColor="whiteAlpha.300" />}
        >
          <Heading size={"md"}> View Refunds </Heading>
          <VStack justifyContent="stretch" alignItems="start" gap={2}>
            <VStack alignItems="start" gap={2}>
              <InputComponent
                type="text"
                value={transactionValue}
                name="search"
                handleChange={handleSearchTransactionReqDataChange}
                placeholder={`Search Lulu Refunds by TransactionId...`}
                inputTitle="Search by Transaction Id"
                leftElement={<MdSearch size="1.5rem" />}
                width="30rem"
                maxWidth="100%"
                size="md"
                autoComplete="off"
                spellCheck={false}
              />
            </VStack>
            <VStack alignItems="start" gap={2}>
              <InputComponent
                type="text"
                value={sheetValue}
                name="search"
                handleChange={handleSearchRefundSheetReqDataChange}
                placeholder={`Search Lulu Refunds by Sheet Id...`}
                inputTitle="Search by Sheet Id"
                leftElement={<MdSearch size="1.5rem" />}
                width="30rem"
                maxWidth="100%"
                size="md"
                autoComplete="off"
                spellCheck={false}
              />
            </VStack>

            <HStack alignItems="start" gap={4}>
              <VStack alignItems={"start"} gap={2}>
                <InputComponent
                  type="date"
                  value={startDate}
                  name="startDate"
                  handleChange={handleTimestampDataChange}
                  inputTitle="Start Date"
                  placeholder="dd/mm/yyyy"
                  maxWidth="14rem"
                  size="md"
                />
              </VStack>
              <VStack alignItems={"start"} gap={2}>
                <InputComponent
                  type="date"
                  value={endDate}
                  max={dayjs().format('YYYY-MM-DD')}
                  name="endDate"
                  handleChange={handleTimestampDataChange}
                  inputTitle="End Date"
                  placeholder="dd/mm/yyyy"
                  maxWidth="14rem"
                  size="md"
                />
              </VStack>
            </HStack>

            <VStack alignItems={"start"} spacing={0}>
              <FormLabel>Filter(s)</FormLabel>
              <FilterSelect
                filtersData={Object.values(LULU_REFUND_DASHBOARD_SEARCH_FILTERS)}
                handleFilterSubmit={handleFilterSubmit}
              />
            </VStack>

            <Button
              color="black"
              rounded="lg"
              colorScheme="brand"
              py={2}
              onClick={() => searchLuluRefunds()}
              fontWeight={600}
              isLoading={isLoading}
              alignSelf="start"
              mt={4}
            >
              Search
            </Button>
          </VStack>

          {totalPages > 1 &&
            <Pagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          }

          {isLoading ? (
            <Flex justifyContent="center" alignItems="center" w="full" h="full">
              <Spinner thickness="4px" size="lg" color="colorPrimary" />
            </Flex>
          ) : isError ? (
            <Box color="red.500" p={4} borderRadius="md" borderWidth="1px">
              <Text>An error occurred while fetching data: {error}</Text>
            </Box>
          ) : luluRefundList?.length ? (
            <VStack alignItems="start" gap={4}>
              {/*Change the card type to lulu refund card type*/}
              {luluRefundList.map((item) => (
                <GenericCard
                  key={item?.transactionId + '-' + item?.orderId}
                  cardType={CARD_TYPE.LULU_REFUND_TRANSACTION_CARD}
                  maxW={'100%'}
                  cardData={item}
                  ActionButtons={[
                    <Button
                      colorScheme="brand"
                      color="black"
                      width="auto"
                      size="md"
                      fontSize="sm"
                      mt={3}
                      onClick={() => setSelectedTransaction(item)}
                    >
                      Details
                    </Button>
                  ]}
                />
              ))}
            </VStack>
          ) : (
            <Text color="white" fontSize="md">
              No Lulu Order Refunds found
            </Text>
          )}
        </VStack>
  );
};

export default ViewRefunds;
