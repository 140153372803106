import React from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button
} from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

const VanceUserKycDetails = ({ details }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant={'plain'}
          color={'white'}
          sx={{
            padding: '0px',
            margin: '0px'
          }}
        >
          <MdInfoOutline />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="altGray.200"
        color="white"
        border={0}
        width="unset"
        maxWidth="100vw"
      >
        <PopoverArrow bg="altGray.200" boxShadow={'none !important'} />
        <PopoverCloseButton />
        <PopoverBody p={3} pb={1}>
          <Box pt={4}>
            <TableContainer
              alignSelf={{
                base: 'flex-start',
                lg: 'center'
              }}
            >
              <Table variant="simple" maxWidth={'100%'}>
                <Tbody maxWidth={'100%'}>
                  {details.map((item) => (
                    <Tr key={item.name} maxWidth={'100%'} fontSize={'sm'}>
                      <Td
                        color={'shadedGray.300'}
                        pt="0"
                        pl={'0'}
                        pr={0}
                        border="none"
                        maxWidth={'20rem'}
                        whiteSpace={'break-word'}
                        pb={'0.75rem'}
                      >
                        {item.name}:
                      </Td>
                      <Td color={'white'} pt="0" border="none" pb={'0.75rem'}>
                        {item.value}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default VanceUserKycDetails;
