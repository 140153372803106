import api from '../../api';
import { setAlert } from './alertActions';
import { RDA_DASHBOARD_CONSTANTS as actionTypes } from './../constants';

export const getTrueLayerBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getTrueLayerBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const saveHoliday =
  ({ reqBody, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.saveHoliday({ reqBody });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(setAlert({ title: error?.message, status: 'error' }));
    } finally {
      onComplete && onComplete();
    }
  };
export const getYblBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getYblBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getYblPotBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getYblPotBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getRdaGbpBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getRdaGbpBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const getRdaInrBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getRdaGbpBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const getOverallTxns =
  ({ status, fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_OVERALL_TXNS
      });

      const { data } = await api.rdaDashboard.getOverallTxns({
        status,
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_OVERALL_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_OVERALL_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getTrueLayerPartnerTxns =
  ({ fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_TL_TXNS
      });

      const { data } = await api.rdaDashboard.getTrueLayerPartnerTxns({
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_TL_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_TL_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getClearBankPartnerTxns =
  ({ transactionType, fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_CB_TXNS
      });

      const { data } = await api.rdaDashboard.getClearBankPartnerTxns({
        fromDate,
        pageNumber,
        pageSize,
        transactionType
      });

      dispatch({
        type: actionTypes.GET_CB_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_CB_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getYblPartnerTxns =
  ({ fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_YBL_PARTNER_TXNS
      });

      const { data } = await api.rdaDashboard.getYblPartnerTxns({
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_YBL_PARTNER_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_YBL_PARTNER_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };
export const getAllHolidays = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GETTING_HOLIDAY_DATA
    });

    const { data } = await api.rdaDashboard.getHolidays();

    dispatch({
      type: actionTypes.GETTING_HOLIDAY_DATA_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: actionTypes.GETTING_HOLIDAY_DATA_FAILURE });
  }
};

export const softDeleteHoliday =
  ({ id, onSuccess }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.softDeleteHoliday(id);
    } catch (error) {
      dispatch(setAlert({ title: 'Error Deleting Holiday', status: 'error' }));
    } finally {
      onSuccess && onSuccess();
    }
  };
export const getClearBankAccountDetails =
  ({ account_type, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GETTING_CLEAR_BANK_ACCOUNT_DETAILS });
      const { data } = await api.rdaDashboard.getClearBankAccountDetails();
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      dispatch(setAlert({ title: error?.message, status: 'error' }));
    } finally {
      onComplete && onComplete();
    }
  };
export const getSafeGaurdingBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getSafeGuardingBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(setAlert({ title: error?.message, status: 'error' }));
    } finally {
      onComplete && onComplete();
    }
  };
export const getOperatingAccountBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getOperatingAccountBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(setAlert({ title: error?.message, status: 'error' }));
    } finally {
      onComplete && onComplete();
    }
  };

export const sweepOutBalanceSafeGuarding =
  ({ onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.sweepOutBalanceSafeGuarding();
      dispatch(
        setAlert({
          title: 'Successfully sweeped out safegaurding balance',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const withdrawFundsFromSafeguardingAccount =
  ({ onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.withdrawFundsFromSafeguardingAccount();
      dispatch(
        setAlert({
          title: 'Successfully withdrew funds from safeguarding account',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const withdrawFundsFromOperatingAccountRDA =
  ({ onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.withdrawFundsFromOperatingAccountRDA();
      dispatch(
        setAlert({
          title: 'Successfully withdrew funds from operating account (RDA)',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const withdrawFundsFromOperatingAccountVDA =
  ({ onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.withdrawFundsFromOperatingAccountVDA();
      dispatch(
        setAlert({
          title: 'Successfully withdrew funds from operating account (VDA)',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const withdrawRefundedFundsFromOperating =
  ({ onComplete }) =>
  async (dispatch) => {
    try {
      await api.rdaDashboard.withdrawRefundedFundsFromOperating();
      dispatch(
        setAlert({
          title: 'Successfully withdrew refunded funds from operating account',
          status: 'success'
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
