import {
    Box,
    Flex,
    Grid,
    HStack,
    Icon,
    Spinner,
    Stack,
    StackDivider,
    Text,
    VStack
} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import useIsMobile from '../../hooks/useIsMobile';
import {getProviderEntityDetails, getTxnMonitoringByFulfillmentId} from "../../store/actions/ordersActions";
import CopyableText from "../../components/CopyableText";
import {formatDate, getTxnMonitoringStatusColor} from "../../utils";
import usePromise from "../../hooks/usePromise";
import {TRANSACTION_MONITORING_PROVIDER} from "../../constants";


const FulfillmentDetails = ({
    fulfillmentId,
    setSumsubProviderId,
    maxW = 'none',
    isSidebar = false
}) => {
    const dispatch = useDispatch();
    const [txnMonitoringData, setTxnMonitoringData] = useState(null);
    const {isLoading: txnDataLoading, run: txnDataRun, error: txnError, data: txnData} = usePromise();
    const {isLoading: providerLoading, run: providerRun, error: providerError, data: providers} = usePromise();
    const [providersData, setProvidersData] = useState(null);
    const isMobile = useIsMobile();

    const triggerGetTxnMonitoringByFulfillmentId = useCallback( () => {
        try {
            const resPromise = dispatch(
                getTxnMonitoringByFulfillmentId({
                    fulfillmentId: fulfillmentId,
                })
            );
            txnDataRun(resPromise);
        } catch (error) {
            console.error("Error :", error);
        }
    }, [fulfillmentId, dispatch, txnDataRun]);

    const triggerGetProviderEntityDetails = useCallback( () => {
        try {
            const resPromise = dispatch(
                getProviderEntityDetails({
                    fulfillmentId: fulfillmentId,
                })
            );
            providerRun(resPromise);
        } catch (error) {
            console.error("Error :", error);
        }
    }, [fulfillmentId, dispatch, providerRun]);

    useEffect(() => {
        if (providers?.length) {
            setProvidersData(providers);
            const sumsubProvider = providers.find(
                (data) => data.provider === TRANSACTION_MONITORING_PROVIDER.SUMSUB
            );
            if (sumsubProvider) {
                setSumsubProviderId(sumsubProvider?.providerClientId);
            }
        }
        return () => {
            setSumsubProviderId(null);
        };
    }, [providers]);

    useEffect(() => {
        if (txnData) {
            setTxnMonitoringData(txnData);
        }
    }, [txnData]);

    useEffect(() => {
       triggerGetTxnMonitoringByFulfillmentId();
       triggerGetProviderEntityDetails();
    }, [fulfillmentId]);

    return (
        <Box
            position={"relative"}
            w={isSidebar ? "95%" : "100%"}
            alignItems={"stretch"}
            gap={8}
            maxW={maxW}
        >
            {txnDataLoading ? (
                <Flex justifyContent="center" alignItems="center" w="full" h="full">
                    <Spinner thickness="4px"  size="lg" color="colorPrimary" />
                </Flex>
            ) : txnError ? (
                <Box
                    color="red.500"
                    p={4}
                    bg="backGround"
                    border={'1px solid'}
                    borderColor={'whiteAlpha.500'}
                    borderRadius="xl"
                    borderWidth="1px"

                >
                    <Text>An error occurred while fetching data: {txnError?.message}</Text>
                </Box>
            ) : (
                <VStack
                    bg="backGround"
                    borderRadius="12px"
                    divider={<StackDivider style={{ margin: "0" }} borderColor="whiteAlpha.300" />}
                >
                    <Grid
                        gridTemplateColumns={
                            isSidebar
                                ? { base: "repeat(1, auto)" }
                                : { base: "repeat(2, auto)", md: "repeat(3, auto)" }
                        }
                        rowGap={2}
                        w={"90%"}
                        justifyContent="space-between"
                        m={"1rem"}
                    >
                        <HStack>
                            <Text fontSize={{ base: "sm", sm: "md" }} color="gray">
                                Fulfillment ID:
                            </Text>
                            <CopyableText title={"Order ID"} fontSize={{ base: "sm", sm: "md" }} color="white">
                                {txnMonitoringData?.fulfillmentId}
                            </CopyableText>
                        </HStack>
                        <HStack>
                            <Icon
                                viewBox="0 0 200 200"
                                color={getTxnMonitoringStatusColor(txnMonitoringData?.status)}
                            >
                                <path
                                    fill="currentColor"
                                    d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                />
                            </Icon>
                            <Text fontSize={{ base: "sm", sm: "md" }} color="white">
                                {txnMonitoringData?.status}
                            </Text>
                        </HStack>
                        <Stack direction={{ base: "column", sm: "row" }}>
                            <Text fontSize={{ base: "sm", sm: "md" }} color="gray">
                                Created on:
                            </Text>
                            <Text fontSize={{ base: "sm", sm: "md" }} color="white">
                                {formatDate(txnMonitoringData?.createdAt)}
                            </Text>
                        </Stack>
                    </Grid>
                    <Grid
                        gridTemplateColumns={
                            isSidebar
                                ? { base: "1fr" }
                                : { base: "1fr", md: `1fr 1fr ${isMobile ? "1.2fr" : "1fr"} 1.2fr auto` }
                        }
                        gap={2}
                        alignItems={"flex-start"}
                        w={"90%"}
                        m={"1rem"}
                        textAlign={{ base: "end", md: "start" }}
                    >
                        {[
                            { label: "Resolving Provider", value: txnMonitoringData?.resolvingProvider || "-" },
                            { label: "Order ID", value: txnMonitoringData?.orderId || "-", isCopyable: true },
                            { label: "User ID", value: txnMonitoringData?.userId || "-", isCopyable: true },
                            { label: "Provider Client Id", value: txnMonitoringData?.providerClientId || "-" },
                            { label: "Remarks", value: txnMonitoringData?.remarks || "-" },
                        ].map(({ label, value, isCopyable }, index) => (
                            <Stack
                                key={index}
                                direction={{ base: "row", md: "column" }}
                                justifyContent={"space-between"}
                            >
                                <Text fontSize={"sm"} color={"gray"}>{label}</Text>
                                {isCopyable ? (
                                    <CopyableText fontSize={"sm"} color={"white"}>{value}</CopyableText>
                                ) : (
                                    <Text fontSize={"sm"} color={"white"}>{value}</Text>
                                )}
                            </Stack>
                        ))}
                    </Grid>
                    <Grid
                        gap={2}
                        alignItems={"flex-start"}
                        w={"90%"}
                        m={"1rem"}
                        textAlign={{ base: "end", md: "start" }}
                    >
                        <Text fontSize={{ base: "sm", sm: "md" }} color="white">
                            Entity Providers:
                        </Text>
                        {providerLoading ? (
                            <Flex justifyContent="center" alignItems="center" w="full" h="full">
                                <Spinner thickness="4px" size="lg" color="colorPrimary" />
                            </Flex>
                        ) : providerError ? (
                            <Box color="red.500"
                                 p={4}
                                 bg="backGround"
                                 border={'1px solid'}
                                 borderColor={'whiteAlpha.500'}
                                 borderRadius="xl"
                                 borderWidth="1px"
                            >
                                <Text>An error occurred while fetching data: {providerError?.message}</Text>
                            </Box>
                        ) : providersData?.length ? (
                            <VStack alignItems="start" gap={2}>
                                {providersData.map((item, index) => (
                                    <VStack
                                        key={index}
                                        border={"1px solid"}
                                        borderColor={"whiteAlpha.300"}
                                        borderRadius="12px"
                                        p={4}
                                        width={"100%"}
                                        alignItems={'stretch'}
                                        bg={"black"}
                                        _hover={{
                                            bg: "#333333"
                                        }}
                                    >
                                        <HStack>
                                            <Icon
                                                viewBox="0 0 200 200"
                                                color={getTxnMonitoringStatusColor(item?.status)}
                                                w="12px"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                                                />
                                            </Icon>
                                            <Text fontSize={{ base: "sm", sm: "md" }} color="white">
                                                {item?.status}
                                            </Text>
                                        </HStack>
                                        <HStack direction={{ base: "column", sm: "row" }} justifyContent="flex-start">
                                            <Text fontSize={{ base: "sm", sm: "md" }} color="gray">
                                                Created At:
                                            </Text>
                                            <Text fontSize={{ base: "sm", sm: "md" }} color="white">
                                                {formatDate(item?.createdAt)}
                                            </Text>
                                        </HStack>
                                        <HStack direction={{ base: "row", md: "column" }} justifyContent="flex-start">
                                            <Text fontSize={"sm"} color={"gray"}>Provider:</Text>
                                            <Text fontSize={"sm"} color={"white"} fontWeight={'semibold'}>{item?.provider || "-"}</Text>
                                        </HStack>
                                    </VStack>
                                ))}
                            </VStack>
                        ) : (
                            <Text color="white" fontSize="md">
                                No Providers Data found
                            </Text>
                        )}
                    </Grid>
                </VStack>
            )}
        </Box>
    );
};

export default FulfillmentDetails;