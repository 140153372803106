export const TRANSFER_DELAY_TABLE_CONFIG = [
    {
        value: "fulfillmentProvider",
        label: "Fulfillment Provider",
        caption: null,
    },
    {
        value: "averageTime",
        label: "Average Time",
        caption: "(in hrs)",
    },
    {
        value: "currentTime",
        label: "Current Time",
        caption: "(in hrs)",
    },
    {
        value: "warningThreshold",
        label: "Warning Threshold",
        caption: "(in hrs)",
    },
    {
        value: "alertThreshold",
        label: "Alert Threshold",
        caption: "(in hrs)",
    },
    {
        value: "customWarningMessage",
        label: "Custom Warning Message",
        caption: null,
    },
    {
        value: "customAlertMessage",
        label: "Custom Alert Message",
        caption: null,
    },
];

export const TRANSFER_DELAY_FORM_FIELD_CONFIG = [
    {
        value: "fulfillmentProvider",
        name: "fulfillmentProvider",
        label: "Fulfillment Provider",
        isEditable: false,
        type: "header",
    },
    {
        value: "averageTime",
        name: "averageTime",
        label: "Average Time",
        caption: "(in hours)",
        isEditable: true,
        type: "input",
        inputType: "number"
    },
    {
        value: "currentTime",
        name: "currentTime",
        label: "Current Time",
        caption: "(in hours)",
        isEditable: true,
        type: "input",
        inputType: "number",
    },
    {
        value: "warningThreshold",
        name: "warningThreshold",
        label: "Warning Threshold",
        caption: "(in hours)",
        isEditable: true,
        type: "input",
        inputType: "number",
    },
    {
        value: "alertThreshold",
        name: "alertThreshold",
        label: "Alert Threshold",
        caption: "(in hours)",
        isEditable: true,
        type: "input",
        inputType: "number",
    },
    {
        value: "customWarningMessage",
        name: "customWarningMessage",
        label: "Custom Warning Message",
        isEditable: true,
        type: "textarea",
        inputType: "string",
    },
    {
        value: "customAlertMessage",
        name: "customAlertMessage",
        label: "Custom Alert Message",
        isEditable: true,
        type: "textarea",
        inputType: "string",
    },
];
