import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Spinner,
    StackDivider,
    Text,
    VStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRFIFilters,
  getRfiTransactions
} from '../../store/actions/falconDashboardActions';
import { updateFalconRFITransactionsReqData } from '../../store/actions/reqDataActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';
import { FALCON_RFI_TRANSACTION_DEFAULT_FILTERS } from "./constant";
import { CARD_TYPE } from '../../constants';
import Pagination from '../../components/Pagination';
import useDebounce from '../../hooks/useDebounce';
import SearchBox from '../../components/SearchBox/SearchBox';
import FilterSelect from '../../components/FiltersBox/FilterSelect';
import GenericCard from '../../components/GenericCard';
import { FaArrowRightLong } from 'react-icons/fa6';


export const RFITransactionDashboard = ({ setSelectedRfiTrxs, selectedTrx }) => {
  const dispatch = useDispatch();
  const trxs = useSelector((state) => state.falconDashboard.rfiTransactions);
  const reqData = useSelector(
    (state) => state.reqData.falconDashboard.rfiTransactions
  );
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });
  const debouncedValue = useDebounce(searchValue.searchValue, 500);

  useEffect(() => {
    dispatch(
      getRFIFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconRFITransactionsReqData({
              searches: data.search,
              filterValues: data.filters,
              filters: FALCON_RFI_TRANSACTION_DEFAULT_FILTERS
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconRFITransactionsReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getRfiTransactions({ ...reqData }, (data) => {
          dispatch({
            type: types.GET_RFI_TRANSACTIONS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconRFITransactionsReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getRfiTransactions({ ...reqData }, (data) => {
          dispatch({
            type: types.GET_RFI_TRANSACTIONS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconRFITransactionsReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  useEffect(() => {
    dispatch(
      getRfiTransactions({ ...reqData }, (data) => {
        dispatch({
          type: types.GET_RFI_TRANSACTIONS_SUCCESS,
          payload: { data }
        });
        dispatch(
          updateFalconRFITransactionsReqData({
            totalPages: data.totalPages,
            pageSize: data.pageSize
          })
        );
      })
    );
  }, [reqData.filters]);

    const handlePageChange = (page) => {
    dispatch(
      updateFalconRFITransactionsReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleFilterChange = ({ key, filters }) => {
    const updatedFilters = { ...reqData.filters };
    if (filters.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = filters;
    }
    dispatch(
      updateFalconRFITransactionsReqData({
        filters: updatedFilters
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
  };

  return (
    <VStack
      overflowY={{ base: 'initial', lg: 'scroll' }}
      overflowX="hidden"
      alignItems="stretch"
      gap={4}
      px={4}
      divider={<StackDivider borderColor="whiteAlpha.300" />}
    >
      <Heading size={"md"}> RFI Transactions </Heading>
        {!reqData.isLoading && !reqData.isError > 0 && (
          <>
            {reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )}
            {reqData.filterValues && (
              <FilterSelect
                filtersData={reqData.filterValues}
                handleFilterSubmit={handleFilterChange}
                defaultFiltersData={FALCON_RFI_TRANSACTION_DEFAULT_FILTERS}
              />
            )}
          </>
        )}

        {/* Pagination */}
        {reqData?.totalPages > 1 && (
          <Pagination
            currentPage={reqData.pageNumber + 1}
            onPageChange={handlePageChange}
            totalPages={reqData.totalPages}
          />
        )}


        {trxs.isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : trxs.data?.length > 0 ? (
          <VStack alignItems="start" gap={4}>
          {trxs.data.map((trx, index) => (
            <GenericCard
              key={index}
              cardData={trx}
              cardType={CARD_TYPE.FALCON_SERVICE_RFI_TRANSACTION_CARD}
              selected={trx===selectedTrx}
              maxW={'100%'}
              ActionButtons={[
                <Button
                  colorScheme="brand"
                  color="black"
                  width="8rem"
                  size="sm"
                  fontSize="sm"
                  mt={3}
                  onClick={() => {
                    setSelectedRfiTrxs(trx);
                  }}
                  rightIcon={<FaArrowRightLong/>}
                >
                  Details
                </Button>,
              ]}
            />
            ))
          }
          </VStack>
        ) : (
            <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
                <Text color="white" fontSize="md">
                    No Transactions in RFI to show
                </Text>
            </Box>
        )}
    </VStack>
  );
};
