export const RETRY_BENEFICIARY_VERIFICATION_FORM_CONFIG = [
  {
    value: "isUsingPreviousVerifierData",
    label: "Would you like to use previous verifier data?",
    caption: null,
    isEditable: true,
    type: "switch",
    inputType: "boolean",
    size: "md",
  },
];
