import React, { useState, useEffect } from 'react';
import {
    VStack,
    Button,
    Flex,
    Spinner,
    Box
} from '@chakra-ui/react';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import StatusCard from "./StatusCard";


const StatusCount = ({
     fetchDefaultStatusList,
     fetchExpandedStatusList,
     defaultActiveStatusList,
     onStatusClick,
     fetchColorByStatus
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeStatuses, setActiveStatuses] = useState(defaultActiveStatusList || []);
    const [defaultStatus, setDefaultStatus] = useState([]);
    const [expandedStatus, setExpandedStatus] = useState([]);
    const [isLoading, setIsLoading] = useState({ default: true, expanded: true });

    const loadDefaultStatuses =  () => {
        setIsLoading((prev) => ({
            ...prev,
            default: true
        }));

        try {
            const data = fetchDefaultStatusList();
            const formattedData = Object.entries(data).map(([status, count]) => ({
                status,
                title: status,
                count,
            }));
            setDefaultStatus(formattedData);
        } catch (error) {
        } finally {
            setIsLoading((prev) => ({
                ...prev,
                default: false
            }));
        }
    }

    const loadExpandedStatuses =  () => {
        setIsLoading((prev) => ({
            ...prev,
            expanded: true
        }));

        try {
            const data = fetchExpandedStatusList();
            const formattedData = Object.entries(data).map(([status, count]) => ({
                status,
                title: status,
                count,
            }));
            setExpandedStatus(formattedData);
        } catch (error) {
        } finally {
            setIsLoading((prev) => ({
                ...prev,
                expanded: false
            }));
        }
    };

    useEffect(() => {
        loadDefaultStatuses();
    }, [fetchDefaultStatusList]);

    useEffect(() => {
        if (isExpanded && expandedStatus.length === 0) {
            loadExpandedStatuses();
        }
    }, [isExpanded, expandedStatus, fetchExpandedStatusList]);

    const toggleStatus = (status) => {
        setActiveStatuses((prev) => {
            const isPresent = prev.includes(status);
            const updatedStatuses = isPresent
                ? prev.filter((s) => s !== status)
                : [...prev, status];
            onStatusClick(updatedStatuses);
            return updatedStatuses;
        });
    };

    return (
        <Flex
            position="relative"
            maxW={'100%'}
            w="max-content"
            justifyContent="space-between"
            gap={2} p={2}
            borderRadius="xl"
            bg="backGround"
        >
            {isLoading.default ? (
                <Flex justifyContent="center" alignItems="center" w="full" h="full">
                    <Spinner thickness="4px" size="lg" color="colorPrimary" />
                </Flex>
            ) : (
                <>
                    <VStack p={0}>
                        <Button
                            colorScheme="brand"
                            size="sm"
                            bg="black"
                            border="2px solid"
                            borderColor="whiteAlpha.500"
                            borderRadius="xl"
                            p={0}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            <Box
                                as="span"
                                transition="transform 0.3s ease-in-out"
                                transform={isExpanded ? "rotate(0deg)" : "rotate(180deg)"}
                                display="inline-block"
                            >
                                <MdOutlineKeyboardDoubleArrowLeft />
                            </Box>
                        </Button>
                    </VStack>

                    <Flex w="max-content"
                          gap={2}
                          flexWrap={'wrap'}
                          borderRadius="xl"
                          bg="backGround"
                    >
                        { !isExpanded ? (
                            defaultStatus?.map((status) => (
                                <StatusCard
                                    key={status.status}
                                    status={status}
                                    onClick={toggleStatus}
                                    isActive={activeStatuses.includes(status.status)}
                                    activeColor={fetchColorByStatus(status.status)}
                                />
                            ))
                        ) : isLoading.expanded ? (
                            <Flex justifyContent="center" alignItems="center" w="full" h="full">
                                <Spinner thickness="4px" size="lg" color="colorPrimary" />
                            </Flex>
                        ) : (
                            expandedStatus?.map((status) => (
                                <StatusCard
                                    key={status.status}
                                    status={status}
                                    onClick={toggleStatus}
                                    isActive={activeStatuses.includes(status.status)}
                                    activeColor={fetchColorByStatus(status.status)}
                                />
                            ))
                        )}
                    </Flex>
                </>
            )}
        </Flex>
    );
};

export default StatusCount;