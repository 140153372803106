import {formatDate, formatNumberWithCommas} from '../../utils';
import {
  createClient,
  createVendor,
  getClientDetails,
  getClients,
  getRfiTransactionDetails,
  getVendorDetails,
  getVendors,
  reEnableVendor,
  rejectByPartner,
  submitToPartner,
  updateClient,
  updateVendor
} from '../../store/actions/falconDashboardActions';
import {updateFalconClientReqData, updateFalconVendorReqData} from '../../store/actions/reqDataActions';
import {FALCON_DASHBOARD_CONSTANTS} from '../../store/constants';
import {decamelizeKeys} from 'humps';

export const clientsToEntity = (clients) => {
  return clients.map(clientToEntity);
};

export const entityToClients = (entity) => {
  return entity.reduce((result, item) => {
    result[item.name] = item.value;
    return result;
  }, {});
};

const statusTypes = {
  GLOBAL_STATUS: 'GLOBAL_STATUS',
  VENDOR_STATUS: 'VENDOR_STATUS',
  FAILURE_RATE_LIMITER_STATUS: 'FAILURE_RATE_LIMITER_STATUS',
  REVERSAL_RATE_LIMITER_STATUS: 'REVERSAL_RATE_LIMITER_STATUS'
};

const statusColors = (statusKey, statusValue) => {
  switch (statusKey) {
    case statusTypes.GLOBAL_STATUS:
      return {
        color: statusValue ? 'green' : 'red',
        text: statusValue ? 'Active' : 'InActive'
      };
    case statusTypes.VENDOR_STATUS:
    case statusTypes.FAILURE_RATE_LIMITER_STATUS:
    case statusTypes.REVERSAL_RATE_LIMITER_STATUS:
      return {
        color: statusValue ? 'green' : 'red',
        text: statusValue ? 'Active' : 'Rate Limited'
      };
  }
};

const clientToEntity = (client) => {
  return {
    ...client,
    name: {
      text: 'Client Name:',
      value: client.clientName
    },
    createdAt: formatDate(client.createdAt),
    updatedAt: formatDate(client.updatedAt),
    currency: {
      text: 'Source Currency:',
      value: client.sourceCurrency
    }
  };
};

export const clientToEntityDetails = (clientDetails) => {
  return [
    {
      heading: 'Client Details',
      data: [
        {
          title: 'Client Name',
          value: clientDetails?.clientName || '-',
          type: 'text',
          isCreatable: true,
          isEditable: false,
          name: 'clientName'
        },
        {
          title: 'Created At',
          value: formatDate(clientDetails?.createdAt) || '-',
          type: 'text'
        },
        {
          title: 'Updated At',
          value: formatDate(clientDetails?.updatedAt) || '-',
          type: 'text'
        },
        {
          title: 'Source Currency',
          value: clientDetails?.sourceCurrency || '-',
          type: 'select',
          key: {
            value: 'currencies',
            subKey: {
              value: 'source'
            }
          },
          isCreatable: true,
          isEditable: false,
          name: 'sourceCurrency'
        },
        {
          title: 'Fallback Enabled',
          value: clientDetails?.isFallbackEnabled || false,
          type: 'switch',
          default: true,
          isCreatable: true,
          isEditable: false,
          name: 'isFallbackEnabled'
        },
        {
          title: 'VDA Enabled',
          value: clientDetails?.isVdaEnabled || false,
          type: 'switch',
          default: true,
          isCreatable: true,
          isEditable: false,
          name: 'isVdaEnabled'
        },
        {
          title: 'RFI Enabled',
          value: clientDetails?.isRfiEnabled || false,
          type: 'switch',
          default: true,
          isCreatable: true,
          isEditable: false,
          name: 'isRfiEnabled'
        }
      ],
      isSectionVisibleForUpdate: true,
      type: 'object'
    },
    {
      heading: 'Client Balance Details',
      data: [
        {
          title: 'Client Name',
          value: clientDetails?.clientName || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'clientName'
        },
        {
          title: 'Source Currency',
          value: clientDetails?.sourceCurrency || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'sourceCurrency'
        },
        {
          title: 'Balance',
          value: clientDetails?.balance || '-',
          maskValue: formatNumberWithCommas(clientDetails?.balance) || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'balance'
        }
      ],
      type: 'object',
      name: 'clientBalance',
      isCreatable: false,
      isEditable: false,
      isSectionVisibleForUpdate: false,
      allowSeparateEdit: 'editClientBalance'
    },
    {
      heading: 'Whitelisted IPs',
      data: clientDetails?.whitelistedIps || [],
      isCreatable: true,
      isEditable: false,
      isSectionVisibleForUpdate: true,
      name: 'whitelistedIps',
      type: 'list',
      placeHolderText: 'Enter IP'
    },
    {
      heading: 'Configurations',
      data: [
        {
          title: 'Currency',
          value: clientDetails?.clientConfig?.currency || '-',
          type: 'select',
          key: {
            value: 'currencies',
            subKey: {
              value: 'payout'
            }
          },
          isCreatable: true,
          isEditable: false,
          name: 'currency'
        },
        {
          title: 'Daily Limit',
          value: clientDetails?.clientConfig?.dailyLimit || '-',
          maskValue:
            formatNumberWithCommas(clientDetails?.clientConfig?.dailyLimit) ||
            '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'dailyLimit'
        },
        {
          title: 'Exchange Rate',
          value: clientDetails?.clientConfig?.exchangeRate || '-',
          maskValue:
            formatNumberWithCommas(clientDetails?.clientConfig?.exchangeRate) ||
            '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'exchangeRate'
        },
        {
          title: 'Exchange Rate Multiplier',
          value: clientDetails?.clientConfig?.exchangeRateMultiplier || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'exchangeRateMultiplier'
        },
        {
          title: 'Fee',
          value: clientDetails?.clientConfig?.fee || '-',
          maskValue:
            formatNumberWithCommas(clientDetails?.clientConfig?.fee) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'fee'
        },
        {
          title: 'Max Limit Per Transactions',
          value: clientDetails?.clientConfig?.maxLimitPerTransaction || '-',
          maskValue:
            formatNumberWithCommas(
              clientDetails?.clientConfig?.maxLimitPerTransaction
            ) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'maxLimitPerTransaction'
        },
        {
          title: 'Min Limit Per Transactions',
          value: clientDetails?.clientConfig?.minLimitPerTransaction || '-',
          maskValue:
            formatNumberWithCommas(
              clientDetails?.clientConfig?.minLimitPerTransaction
            ) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'minLimitPerTransaction'
        },
        {
          title: 'VDA Attempts',
          value: clientDetails?.clientConfig?.numOfVdaAttempts || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'numOfVdaAttempts'
        },
        {
          title: 'Pending Orders Limit',
          value: clientDetails?.clientConfig?.pendingOrdersLimit || '-',
          maskValue: formatNumberWithCommas(
            clientDetails?.clientConfig?.pendingOrdersLimit
          ),
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'pendingOrdersLimit'
        },
        {
          title: 'SLA Time (minutes)',
          value: clientDetails?.clientConfig?.slaTimeInMinutes || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'slaTimeInMinutes'
        },
        {
          title: 'Extra Info',
          value: JSON.stringify(
            decamelizeKeys(clientDetails?.clientConfig?.extras || {})
          ),
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'extras'
        }
      ],
      type: 'object',
      isSectionVisibleForUpdate: true,
      allowSeparateEdit: 'editConfiguration'
    },
    {
      heading: 'Payout Partners',
      data: clientDetails?.clientConfig?.payoutPartners.map((partner) => ({
        partnerName: {
          title: 'Partner Name',
          value: partner.partnerName || '-',
          type: 'select',
          key: {
            value: 'payoutPartners'
          }
        },
        retryCount: {
          title: 'Retry Count',
          value: partner.retryCount || '-',
          type: 'text'
        },
        priority: {
          title: 'Priority',
          value: partner.priority,
          type: 'text'
        }
      })) || [
        {
          partnerName: {
            title: 'Partner Name',
            value: '-',
            type: 'select',
            key: {
              value: 'payoutPartners'
            }
          },
          retryCount: {
            title: 'Retry Count',
            value: '-',
            type: 'text'
          },
          priority: {
            title: 'Priority',
            value: '-',
            type: 'text'
          }
        }
      ],
      type: 'cards',
      name: 'payoutPartners',
      isCreatable: true,
      isEditable: false,
      isSectionVisibleForUpdate: true,
      allowSeparateEdit: 'editPartners'
    }
  ];
};

export const entityToFormData = (entity) => {
  const formData = {};
  entity.map((detail) => {
    if (detail.isCreatable) {
      if (detail.type === 'cards') {
        formData[detail.name] = detail.data.map((data) => {
          const value = {};
          Object.keys(data).map((key) => (value[key] = data[key].value));
          return value;
        });
      } else {
        formData[detail.name] = detail.data;
      }
    } else {
      detail.data.map((item) => {
        if (item.isCreatable) {
          formData[item.name] = item.value === '-' ? '' : item.value;
        }
      });
    }
  });
  return formData;
};

export const formDataToClients = (formData) => {
  return {
    clientName: formData.clientName,
    sourceCurrency: formData.sourceCurrency,
    isFallbackEnabled: formData.isFallbackEnabled,
    isVdaEnabled: formData.isVdaEnabled,
    isRfiEnabled: formData.isRfiEnabled,
    whitelistedIps: formData.whitelistedIps,
    payoutConfigs: [
      {
        currency: formData.currency,
        dailyLimit: formData.dailyLimit,
        exchangeRate: formData.exchangeRate,
        exchangeRateMultiplier: formData.exchangeRateMultiplier,
        fee: formData.fee,
        maxLimitPerTransaction: formData.maxLimitPerTransaction,
        minLimitPerTransaction: formData.minLimitPerTransaction,
        numOfVdaAttempts: formData.numOfVdaAttempts,
        pendingOrdersLimit: formData.pendingOrdersLimit,
        slaTimeInMinutes: formData.slaTimeInMinutes,
        payoutPartners: formData.payoutPartners,
        extras: formData.extras && JSON.parse(formData.extras)
      }
    ]
  };
};

export const falconTransactionDetails = (falconTransaction) => {
  return [
    {
      heading: 'Falcon Transaction',
      data: [
        {
          title: 'Transaction Id',
          value: falconTransaction?.transactionId || 'N/A'
        },
        {
          title: 'Client',
          value: falconTransaction?.client || 'N/A'
        },
        {
          title: 'Client Txn Id',
          value: falconTransaction?.clientTxnId || 'N/A'
        },
        {
          title: 'Source Currency',
          value: falconTransaction?.sourceCurrency || 'N/A'
        },
        {
          title: 'Source Amount',
          value: falconTransaction?.sourceAmount || 'N/A'
        },
        {
          title: 'Payout Currency',
          value: falconTransaction?.payoutCurrency || 'N/A'
        },
        {
          title: 'Payout Amount',
          value: falconTransaction?.payoutAmount || 'N/A'
        },
        {
          title: 'Exchange Rate',
          value: falconTransaction?.exchangeRate || 'N/A'
        },
        {
          title: 'Transaction Fee',
          value: falconTransaction?.transactionFee || 'N/A'
        },
        {
          title: 'Recipient Id',
          value: falconTransaction?.recipientId || 'N/A'
        },
        {
          title: 'Sender Id',
          value: falconTransaction?.senderId || 'N/A'
        },
        {
          title: 'Status',
          value: falconTransaction?.status || 'N/A'
        },
        {
          title: 'Transaction Expires At',
          value: falconTransaction?.transactionExpiresAt || 'N/A'
        }
      ]
    }
  ];
};

const formFieldToDynamicFormField = (fieldConfig) => {
  return {
    ...fieldConfig,
    value: fieldConfig?.name,
    label: `${fieldConfig?.title}`,
    isEditable: fieldConfig?.isEditable,
    type: fieldConfig?.type,
    inputType: fieldConfig?.inputType || 'text',
    size: 'sm',
    width: 'auto',
  }
}

export const vendorToGenericCardData = (entity) => {
  return entity.data.reduce((acc, item) => {
    acc[item.name] = item; // Use field name as the key
    return acc;
  }, {});
}

export const vendorsToEntity = (vendors) => {
  return vendors.map(vendorToEntity);
};

const vendorToEntity = (vendor) => {
  return {
    data: [
        {
          title: 'Vendor',
          value: vendor?.name,
          showEditIcon: true,
          position: {
            value: 'sticky',
            style: {
              position: 'sticky',
              zIndex: '1000',
              left: '0',
              backgroundColor: "#111111",
              boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)"
            }
          },
          name: 'name',
          isCreatable: true,
          isEditable: false,
        },
        {
          title: 'Currency',
          value: vendor?.currencyCode,
          isCreatable: true,
          isEditable: false,
          name: 'currency'
        },
        {
          title: 'Global Enabled',
          value: vendor?.vendorStatus?.isGlobalEnabled,
          maskValue: String(vendor?.vendorStatus?.isGlobalEnabled),
          color: vendor?.vendorStatus?.isGlobalEnabled ? 'colorPrimary' : 'red',
          type: 'icon',
          isCreatable: true,
          isEditable: false,
          name: 'isGlobalEnabled'
        },
        {
          title: 'Vendor Enabled',
          value: vendor?.vendorStatus?.isVendorEnabled,
          maskValue: String(vendor?.vendorStatus?.isVendorEnabled),
          color: vendor?.vendorStatus?.isVendorEnabled ? 'colorPrimary' : 'red',
          type: 'icon',
          isCreatable: true,
          isEditable: false,
          name: 'isVendorEnabled'
        },
        {
          title: 'Failure Rate Limiter Enabled',
          value: vendor?.vendorStatus?.isFailureRateLimiterEnabled,
          maskValue: String(vendor?.vendorStatus?.isFailureRateLimiterEnabled),
          color: vendor?.vendorStatus?.isFailureRateLimiterEnabled ? 'colorPrimary' : 'red',
          type: 'icon',
          isCreatable: true,
          isEditable: false,
          name: 'isFailureRateLimiterEnabled'
        },
        {
          title: 'Reversal Rate Limiter Enabled',
          value: vendor?.vendorStatus?.isReversalRateLimiterEnabled,
          maskValue: String(vendor?.vendorStatus?.isReversalRateLimiterEnabled),
          color: vendor?.vendorStatus?.isReversalRateLimiterEnabled ? 'colorPrimary' : 'red',
          type: 'icon',
          isCreatable: true,
          isEditable: false,
          name: 'isReversalRateLimiterEnabled'
        },
        {
          title: 'Vendor Balance',
          value: vendor?.clientBalance || '-',
          maskValue:
            formatNumberWithCommas(vendor?.clientBalance) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: false,
          isEditable: false,
          name: 'clientBalance'
        },
        {
          title: 'Daily Limit',
          value: vendor?.dailyLimit || '-',
          maskValue: formatNumberWithCommas(vendor?.dailyLimit) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'dailyLimit'
        },
        {
          title: 'Exchange Rate',
          value: vendor?.exchangeRate || '-',
          maskValue: formatNumberWithCommas(vendor?.exchangeRate) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'exchangeRate'
        },
        {
          title: 'Min Limit',
          value: vendor?.minLimitPerTransaction || 0,
          maskValue:
            formatNumberWithCommas(vendor?.minLimitPerTransaction) ||
            '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'minLimitPerTransaction'
        },
        {
          title: 'Max Limit',
          value: vendor?.maxLimitPerTransaction || '-',
          maskValue:
            formatNumberWithCommas(vendor?.maxLimitPerTransaction) ||
            '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'maxLimitPerTransaction'
        },
        {
          title: 'Disabled',
          value: vendor?.isDisabled,
          color: vendor?.isDisabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isDisabled'
        },
        {
          title: 'Payout Disabled',
          value: vendor?.isPayoutDisabled,
          color: vendor?.isPayoutDisabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isPayoutDisabled'
        },
        {
          title: 'Payout Rate Limit Enabled',
          value: vendor?.isPayoutRateLimitEnabled,
          color: vendor?.isPayoutRateLimitEnabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isPayoutRateLimitEnabled'
        },
        {
          title: 'Reversal Allowed',
          value: vendor?.isReversalAllowed,
          color: vendor?.isReversalAllowed ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isReversalAllowed'
        },
        {
          title: 'Pending Orders Amount',
          value: vendor?.pendingOrdersAmtLimit || '-',
          maskValue:
            formatNumberWithCommas(vendor?.pendingOrdersAmtLimit) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'pendingOrdersAmtLimit'
        },
        {
          title: 'Pending Orders Limit',
          value: vendor?.pendingOrdersLimit || '-',
          maskValue:
            formatNumberWithCommas(vendor?.pendingOrdersLimit) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'pendingOrdersLimit'
        },
        {
          title: 'Processing Orders Amount',
          value: vendor?.processingOrdersAmtLimit || '-',
          maskValue:
            formatNumberWithCommas(vendor?.processingOrdersAmtLimit) ||
            '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'processingOrdersAmtLimit'
        },
        {
          title: 'Processing Orders',
          value: vendor?.processingOrdersLimit || '-',
          maskValue:
            formatNumberWithCommas(vendor?.processingOrdersLimit) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'processingOrdersLimit'
        },

        {
          title: 'Payouts Allowed',
          value: vendor?.payoutsAllowedInRateLimitPeriod || '-',
          maskValue: formatNumberWithCommas(vendor?.exchangeRate) || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'payoutsAllowedInRateLimitPeriod'
        },
        {
          title: 'Rate Limit Period',
          value: vendor?.rateLimitPeriodMin || '-',
          type: 'input',
          inputType: 'number',
          isCreatable: true,
          isEditable: true,
          name: 'rateLimitPeriodMin'
        },
        {
          title: 'Created At',
          value: vendor?.createdAt,
          maskValue: formatDate(vendor?.createdAt),
          name: 'createdAt',
          isCreatable: false,
          isEditable: false,
        },
        {
          title: 'Updated At',
          value: vendor?.updatedAt,
          maskValue: formatDate(vendor?.updatedAt),
          name: 'updatedAt',
          isCreatable: false,
          isEditable: false,
        },
    ],
    type: 'object',
    id: vendor?.name,
    isSectionVisibleForUpdate: true,
    allowSeparateEdit: 'editConfiguration'
  }
}

export const vendorToEntityDetails = (vendorDetails) => {
  return [
    {
      heading: 'Vendor Details',
      data: [
        {
          title: 'Vendor Name',
          value: vendorDetails?.name || '-',
          type: 'select',
          key: {
            value: 'payoutPartners'
          },
          isCreatable: true,
          isEditable: false,
          name: 'name'
        },
        {
          title: 'Created At',
          value: formatDate(vendorDetails?.createdAt) || '-',
          type: 'text'
        },
        {
          title: 'Updated At',
          value: formatDate(vendorDetails?.updatedAt) || '-',
          type: 'text'
        },
        {
          title: 'Currency',
          value: vendorDetails?.currency || '-',
          type: 'select',
          key: {
            value: 'currencies',
            subKey: {
              value: 'all'
            }
          },
          isCreatable: true,
          isEditable: false,
          name: 'currency'
        },
        {
          title: 'Global Status',
          value: statusColors(
            statusTypes.GLOBAL_STATUS,
            vendorDetails?.vendorStatus?.isGlobalEnabled
          ).text,
          color: statusColors(
            statusTypes.GLOBAL_STATUS,
            vendorDetails?.vendorStatus?.isGlobalEnabled
          ).color,
          type: 'text',
          displaySettings: statusColors(
            statusTypes.GLOBAL_STATUS,
            vendorDetails?.vendorStatus?.isGlobalEnabled
          ),
          key: {
            value: 'currencies',
            subKey: {
              value: 'all'
            }
          },
          // color: statusColors[],
          isCreatable: false,
          isEditable: false,
          name: 'globalStatus'
        },
        {
          title: 'Vendor Enabled Status',
          value: statusColors(
            statusTypes.VENDOR_STATUS,
            vendorDetails?.vendorStatus?.isVendorEnabled
          ).text,
          color: statusColors(
            statusTypes.VENDOR_STATUS,
            vendorDetails?.vendorStatus?.isVendorEnabled
          ).color,
          type: 'text',
          key: {
            value: 'currencies',
            subKey: {
              value: 'all'
            }
          },
          // color: statusColors[],
          isCreatable: false,
          isEditable: false,
          name: 'globalStatus'
        },
        {
          title: 'Failure Status',
          value: statusColors(
            statusTypes.FAILURE_RATE_LIMITER_STATUS,
            vendorDetails?.vendorStatus?.isFailureRateLimiterEnabled
          ).text,
          color: statusColors(
            statusTypes.FAILURE_RATE_LIMITER_STATUS,
            vendorDetails?.vendorStatus?.isFailureRateLimiterEnabled
          ).color,
          type: 'text',
          key: {
            value: 'currencies',
            subKey: {
              value: 'all'
            }
          },
          isCreatable: false,
          isEditable: false,
          name: 'currency'
        },
        {
          title: 'Reversal Status',
          value: statusColors(
            statusTypes.REVERSAL_RATE_LIMITER_STATUS,
            vendorDetails?.vendorStatus?.isReversalRateLimiterEnabled
          ).text,
          color: statusColors(
            statusTypes.REVERSAL_RATE_LIMITER_STATUS,
            vendorDetails?.vendorStatus?.isReversalRateLimiterEnabled
          ).color,
          type: 'text',
          key: {
            value: 'currencies',
            subKey: {
              value: 'all'
            }
          },
          isCreatable: false,
          isEditable: false,
          name: 'currency'
        }
      ],
      isSectionVisibleForUpdate: true,
      type: 'object'
    },
    {
      heading: 'Configurations',
      data: [
        {
          title: 'Virtual Balance',
          value: vendorDetails?.virtualBalance || '-',
          maskValue: formatNumberWithCommas(vendorDetails?.virtualBalance),
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'virtualBalance'
        },
        {
          title: 'Vendor Balance',
          value: vendorDetails?.clientBalance || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.clientBalance) || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'clientBalance'
        },
        {
          title: 'Daily Limit',
          value: vendorDetails?.dailyLimit || '-',
          maskValue: formatNumberWithCommas(vendorDetails?.dailyLimit) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'dailyLimit'
        },
        {
          title: 'Min Limit Per Transactions',
          value: vendorDetails?.minLimitPerTransaction || 0,
          maskValue:
            formatNumberWithCommas(vendorDetails?.minLimitPerTransaction) ||
            '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'minLimitPerTransaction'
        },
        {
          title: 'Max Limit Per Transactions',
          value: vendorDetails?.maxLimitPerTransaction || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.maxLimitPerTransaction) ||
            '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'maxLimitPerTransaction'
        },
        {
          title: 'Pending Orders Amount Limit',
          value: vendorDetails?.pendingOrdersAmtLimit || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.pendingOrdersAmtLimit) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'pendingOrdersAmtLimit'
        },
        {
          title: 'Pending Orders Limit',
          value: vendorDetails?.pendingOrdersLimit || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.pendingOrdersLimit) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'pendingOrdersLimit'
        },
        {
          title: 'Processing Orders Amount Limit',
          value: vendorDetails?.processingOrdersAmtLimit || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.processingOrdersAmtLimit) ||
            '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'processingOrdersAmtLimit'
        },
        {
          title: 'Processing Orders Limit',
          value: vendorDetails?.processingOrdersLimit || '-',
          maskValue:
            formatNumberWithCommas(vendorDetails?.processingOrdersLimit) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'processingOrdersLimit'
        },
        {
          title: 'Exchange Rate',
          value: vendorDetails?.exchangeRate || '-',
          maskValue: formatNumberWithCommas(vendorDetails?.exchangeRate) || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'exchangeRate'
        },
        {
          title: 'Payout Allowed in Rate Limit Period',
          value: vendorDetails?.payoutsAllowedInRateLimitPeriod || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'payoutsAllowedInRateLimitPeriod'
        },
        {
          title: 'Rate Limit Period Min',
          value: vendorDetails?.rateLimitPeriodMin || '-',
          type: 'text',
          isCreatable: true,
          isEditable: true,
          name: 'rateLimitPeriodMin'
        },
        {
          title: 'Disabled',
          value: vendorDetails?.isDisabled,
          color: vendorDetails?.isDisabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isDisabled'
        },
        {
          title: 'Payout Disabled',
          value: vendorDetails?.isPayoutDisabled,
          color: vendorDetails?.isPayoutDisabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isPayoutDisabled'
        },
        {
          title: 'Payout Rate Limit Enabled',
          value: vendorDetails?.isPayoutRateLimitEnabled,
          color: vendorDetails?.isPayoutRateLimitEnabled ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isPayoutRateLimitEnabled'
        },
        {
          title: 'Reversal Allowed',
          value: vendorDetails?.isReversalAllowed,
          color: vendorDetails?.isReversalAllowed ? 'green' : 'red',
          type: 'switch',
          default: false,
          isCreatable: true,
          isEditable: true,
          name: 'isReversalAllowed'
        }
      ],
      type: 'object',
      isSectionVisibleForUpdate: true,
      allowSeparateEdit: 'editConfiguration'
    },
    {
      heading: 'Reserves',
      data: vendorDetails?.reserves?.map((reserve) => ({
        clientName: {
          title: 'Client Name',
          value: reserve.clientName || '-',
          type: 'text'
        },
        offset: {
          title: 'Offset',
          value: reserve.offset || '-',
          type: 'text'
        },
        reserve: {
          title: 'Reserve',
          value: reserve.reserve || '-',
          maskValue: formatNumberWithCommas(reserve?.reserve) || '-',
          type: 'text'
        },
        currency: {
          title: 'Currency',
          value: reserve.currency || '-',
          type: 'text'
        }
      })) || [
        {
          clientName: {
            title: 'Client Name',
            value: '-',
            type: 'text'
          },
          offset: {
            title: 'Offset',
            value: '-',
            type: 'text'
          },
          reserve: {
            title: 'Reserve',
            value: '-',
            type: 'text'
          },
          currency: {
            title: 'Currency',
            value: '-',
            type: 'select',
            key: {
              value: 'currencies',
              subKey: {
                value: 'all'
              }
            }
          }
        }
      ],
      type: 'cards',
      name: 'reserves',
      isCreatable: true,
      isEditable: false,
      isSectionVisibleForUpdate: true
    }
  ];
};

export const formDataToVendors = (formData) => {
  return {
    name: formData.name,
    currency: formData.currency,
    dailyLimit: formData.dailyLimit,
    maxLimitPerTransaction: formData.maxLimitPerTransaction,
    minLimitPerTransaction: formData.minLimitPerTransaction,
    pendingOrdersLimit: formData.pendingOrdersLimit,
    pendingOrdersAmtLimit: formData.pendingOrdersAmtLimit,
    processingOrdersLimit: formData.processingOrdersLimit,
    processingOrdersAmtLimit: formData.processingOrdersAmtLimit,
    reserves: formData.reserves,
    exchangeRate: formData.exchangeRate,
    isDisabled: formData.isDisabled,
    isPayoutDisabled: formData.isPayoutDisabled,
    isPayoutRateLimitEnabled: formData.isPayoutRateLimitEnabled,
    payoutsAllowedInRateLimitPeriod: formData.payoutsAllowedInRateLimitPeriod,
    rateLimitPeriodMin: formData.rateLimitPeriodMin,
    isReversalAllowed: formData.isReversalAllowed
  };
};

export const clientEntityConfig = {
  title: 'Client',
  entityConvertor: (data) => clientToEntityDetails(data),
  fetchDetails: ({ client, currency }) =>
    getClientDetails({ client, currency }),
  payloadConvertor: (formData) => formDataToClients(formData),
  updateDetails: ({ req, onSuccess }) => updateClient({ req, onSuccess }),
  createDetails: ({ data, onSuccess }) => createClient({ data, onSuccess }),
  updateSearchReq: (req) => updateFalconClientReqData(req),
  getList: (reqData, onSuccess) => getClients(reqData, onSuccess),
  getListSuccess: FALCON_DASHBOARD_CONSTANTS.GET_FALCON_CLIENTS_SUCCESS,
  updateSuccess: FALCON_DASHBOARD_CONSTANTS.UPDATE_FALCON_CLIENT_SUCCESS,
  updateReqConvetor: (data) => ({
    ...data.payoutConfigs[0],
    client: data.clientName
  })
};

export const vendorEntityConfig = {
  title: 'Vendor',
  entityConvertor: (data) => vendorToEntityDetails(data),
  fetchDetails: ({ client, currency }) =>
    getVendorDetails({ vendor: client, currency }),
  reEnable: ({ formData, onComplete }) =>
    reEnableVendor({ formData, onComplete }),
  payloadConvertor: (formData) => formDataToVendors(formData),
  updateDetails: ({ req, onSuccess, onComplete }) => updateVendor({ req, onSuccess, onComplete }),
  createDetails: ({ data, onSuccess }) => createVendor({ data, onSuccess }),
  updateSearchReq: (req) => updateFalconVendorReqData(req),
  getList: (reqData, onSuccess) => getVendors(reqData, onSuccess),
  getListSuccess: FALCON_DASHBOARD_CONSTANTS.GET_FALCON_VENDORS_SUCCESS,
  updateSuccess: FALCON_DASHBOARD_CONSTANTS.UPDATE_FALCON_VENDOR_SUCCESS,
  updateReqConvetor: (data) => ({ ...data, currencyCode: data.currency }),
  toDynamicFormField: (field) => formFieldToDynamicFormField(field),
  vendorToGenericCardData: (vendor) => vendorToGenericCardData(vendor),
};

export const rfiToEntityDetails = (rfiDetails) => {
  return [
    {
      heading: 'RFI Details',
      data: [
        {
          title: 'Partner Name',
          value: rfiDetails?.partnerName || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'partnerName'
        },
        {
          title: 'Status',
          value: rfiDetails?.status || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'status'
        },
        {
          title: 'Payout ID',
          value: rfiDetails?.payoutId || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'payoutId'
        },
        {
          title: 'Request ID',
          value: rfiDetails?.requestId || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'requestId'
        }
      ],
      isSectionVisibleForUpdate: true,
      type: 'object'
    },
    {
      heading: 'Transaction Details',
      data: [
        {
          title: 'ID',
          value: rfiDetails?.transaction?.transactionId || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'transactionId'
        },
        {
          title: 'Status',
          value: rfiDetails?.transaction?.status || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'transactionStatus'
        },
        {
          title: 'Source Currency',
          value: rfiDetails?.transaction?.sourceCurrency || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'sourceCurrency'
        },
        {
          title: 'Source Amount',
          value: rfiDetails?.transaction?.sourceAmount || '-',
          maskValue:
            formatNumberWithCommas(rfiDetails?.transaction?.sourceAmount) ||
            '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'sourceAmount'
        },
        {
          title: 'Payout Currency',
          value: rfiDetails?.transaction?.payoutCurrency || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'payoutCurrency'
        },
        {
          title: 'Payout Amount',
          value: rfiDetails?.transaction?.payoutAmount || '-',
          maskValue:
            formatNumberWithCommas(rfiDetails?.transaction?.payoutAmount) ||
            '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'payoutAmount'
        },
        {
          title: 'Rate',
          value: rfiDetails?.transaction?.rate || '-',
          maskValue:
            formatNumberWithCommas(rfiDetails?.transaction?.rate) || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'rate'
        }
      ],
      isSectionVisibleForUpdate: true,
      type: 'object'
    },
    {
      heading: 'Recipient Details',
      data: [
        {
          title: 'Name',
          value: rfiDetails?.transaction?.recipient?.name || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'recipientName'
        },
        {
          title: 'Account Number',
          value: rfiDetails?.transaction?.recipient?.accountNumber || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'accountNumber'
        },
        {
          title: 'Bank Code',
          value: rfiDetails?.transaction?.recipient?.bankCode || '-',
          type: 'text',
          isCreatable: false,
          isEditable: false,
          name: 'bankCode'
        }
      ],
      isSectionVisibleForUpdate: true,
      type: 'object'
    },
    {
      heading: 'RFI Documents',
      data: rfiDetails?.rfiDocuments.map((document) => ({
        documentType: {
          title: 'Document Type',
          value: document.field || '-',
          type: 'text'
        },
        documentValue: {
          title: 'Document Value',
          value: document.value || '-',
          type: 'text'
        }
      })) || [
        {
          documentType: {
            title: 'Document Type',
            value: '-',
            type: 'text'
          },
          documentValue: {
            title: 'Document Value',
            value: '-',
            type: 'text'
          }
        }
      ],
      type: 'cards',
      name: 'rfiDocuments',
      isCreatable: false,
      isEditable: false,
      isSectionVisibleForUpdate: true,
      buttons: [
        {
          title: 'Update Document',
          condition: rfiDetails?.status === 'PENDING',
          onClick: ({ openModal }) => openModal(),
          color: 'black',
          colorScheme: 'brand'
        },
        {
          title: 'Submitted to Partner',
          condition: rfiDetails?.status === 'SUBMITTED',
          onClick: ({ dispatch }) =>
            dispatch(submitToPartner({ requestId: rfiDetails?.requestId })),
          color: 'black',
          colorScheme: 'brand'
        },
        {
          title: 'Rejected by Partner',
          condition: rfiDetails?.status === 'SUBMITTED',
          onClick: ({ dispatch }) =>
            dispatch(rejectByPartner({ requestId: rfiDetails?.requestId })),
          colorScheme: 'brand',
          variant: 'outline',
          color: 'red'
        }
      ]
    }
  ];
};

export const rfiEntityConfig = {
  title: 'RFI',
  entityConvertor: (data) => rfiToEntityDetails(data),
  fetchDetails: ({ requestId }) => getRfiTransactionDetails(requestId)
};

export const mapStatusCountListToObj = (statusList, defaultStatus) => {
  if (
    !statusList ||
    Object.keys(statusList).length === 0 ||
    defaultStatus.length === 0
  ) {
    return {};
  }

  return defaultStatus.reduce((result, statusKey) => {
    result[statusKey] = statusList[statusKey] || 0;
    return result;
  }, {});
};
export const reEnableConfig = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  payoutPartners
}) => {
  return {
    title: 'Re-Enable Vendor',
    fields: [
      {
        title: 'comment',
        type: 'text',
        label: 'Enter Comment',
        placeholder: 'Enter Your Comment'
      },
      // {
      //   title: 'partnerType',
      //   type: 'select',
      //   label: 'Choose Payout Partner',
      //   options: [
      //     ...payoutPartners.map((payoutPartner) => {
      //       return {
      //         label: payoutPartner,
      //         value: payoutPartner
      //       };
      //     })
      //   ]
      // },
      { title: 'clearLog', type: 'switch', label: 'Clear Log', default: true }
    ],
    submitText: 'Submit',
    isOpen,
    onClose,
    isLoading,
    onSubmit
  };
};
