import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spinner,
  StackDivider,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack
} from '@chakra-ui/react';

import {
  MdAdd,
  MdEdit,
  MdGridOn, MdOfflineBolt,
  MdOutlineCancel, MdOutlinePlayCircleOutline, MdPauseCircleOutline
} from 'react-icons/md';
import { FaArrowRightLong } from 'react-icons/fa6';
import { IoMdCheckmark } from 'react-icons/io';
import { BsViewList } from 'react-icons/bs';

import {
  getVendorFilters,
  getVendors
} from '../../store/actions/falconDashboardActions';
import { updateFalconVendorReqData } from '../../store/actions/reqDataActions';
import { FALCON_DASHBOARD_CONSTANTS as types } from '../../store/constants';

import Pagination from '../../components/Pagination';
import ModalLayout from '../../components/Modal/ModalLayout';
import CreateClientModal from '../../components/FalconServiceDashboard/CreateClientModal';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import SearchBox from '../../components/SearchBox/SearchBox';
import GenericCard from '../../components/GenericCard';
import DynamicFormField from '../../components/Config/DynamicFormField/DynamicFormField';

import useDebounce from '../../hooks/useDebounce';
import useAuthRole from '../../hooks/useAuthRole';

import {
  entityToFormData,
  vendorEntityConfig as config
} from './utils';
import { CARD_TYPE, ROLES } from '../../constants';
import { VENDORS_VIEW_MODE } from './constant';
import { FaDotCircle } from 'react-icons/fa';


export const VendorDashboard = ({ setSelectedVendor, selectedVendor }) => {
  const { hasAnyAuthority } = useAuthRole();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // Redux Store
  const vendors = useSelector((state) => state.falconDashboard.vendors);
  const { viewMode } = vendors;
  const reqData = useSelector((state) => state.reqData.falconDashboard.vendor);
  // Local Store
  const [filterValue, setFilterValue] = useState({});
  const [searchValue, setSearchValue] = useState({
    searchValue: null,
    searchKey: null
  });
  const debouncedValue = useDebounce(searchValue.searchValue, 500);
  const [vendorToEdit, setVendorToEdit] = useState(null);
  const [formData, setFormData] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const {
    isOpen: isCreateModalOpen,
    onClose: closeCreateModal,
    onOpen: openCreateModal
  } = useDisclosure();

  const {
    isOpen: isConfirmUpdateConfigurationModalOpen,
    onOpen: onConfirmUpdateConfigurationModalOpen,
    onClose: onConfirmUpdateConfigurationModalClose
  } = useDisclosure();

  // Setting up the view
  useEffect(() => {
    const viewFromUrl = searchParams.get(VENDORS_VIEW_MODE.KEY);
    if (viewFromUrl === viewMode) return;
    if (viewFromUrl && viewFromUrl !== viewMode) {
      dispatch({
        type: types.UPDATE_FALCON_VENDORS_DASHBOARD_VIEW_MODE,
        payload: viewFromUrl
      });
    } else if (!viewFromUrl) {
      setSearchParams({
        [VENDORS_VIEW_MODE.KEY]: viewMode
      });
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(
      getVendorFilters({
        onSuccess: (data) => {
          dispatch(
            updateFalconVendorReqData({
              searches: data.search,
              filterValues: data.filters
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedValue != null)
      dispatch(
        updateFalconVendorReqData({
          ...searchValue
        })
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (reqData.searchKey)
      dispatch(
        getVendors({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_VENDORS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconVendorReqData({
              totalPages: data.totalPages,
              pageNumber: data.page,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.searchValue, filterValue]);

  useEffect(() => {
    if (reqData.pageNumber != null)
      dispatch(
        getVendors({ ...reqData, filters: filterValue }, (data) => {
          dispatch({
            type: types.GET_FALCON_VENDORS_SUCCESS,
            payload: { data }
          });
          dispatch(
            updateFalconVendorReqData({
              totalPages: data.totalPages,
              pageSize: data.pageSize
            })
          );
        })
      );
  }, [reqData.pageNumber]);

  useEffect(() => {
    if (vendorToEdit) {
      setFormData(entityToFormData([vendorToEdit]));
    }
  }, [vendorToEdit]);

  const handlePageChange = (page) => {
    dispatch(
      updateFalconVendorReqData({
        pageNumber: page - 1
      })
    );
  };

  const handleSearchChange = (search) => {
    setSearchValue(search);
    dispatch(
      updateFalconVendorReqData({
        pageNumber: 0
      })
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'number' ? Number(value) : value
    }));
  };

  const toggleViewMode = () => {
    const newViewMode = (viewMode === VENDORS_VIEW_MODE.CARD) ? VENDORS_VIEW_MODE.TABLE : VENDORS_VIEW_MODE.CARD;
    dispatch({
      type: types.UPDATE_FALCON_VENDORS_DASHBOARD_VIEW_MODE,
      payload: newViewMode
    });
    setSearchParams({
      [VENDORS_VIEW_MODE.KEY]: newViewMode
    });
    setSelectedVendor(null);
    handleEditCleanUp();
  };

  const handleEditMode = useCallback((e, vendor) => {
    e.preventDefault();
    setVendorToEdit(vendor);
  }, []);

  const handleEditCleanUp = useCallback((e) => {
    e?.preventDefault();
    setFormData({});
    setVendorToEdit(null);
    onConfirmUpdateConfigurationModalClose();
  }, []);

  const handleSaveConfig = useCallback(() => {
    if (vendorToEdit) {
      setIsModalLoading(true);
      dispatch(
        config.updateDetails({
          req: config.updateReqConvetor(config.payloadConvertor(formData)),
          onSuccess: (data) => {
            // Update the current vendor details in redux
            dispatch({ type: config.updateSuccess, payload: { data } });
            // Get all the vendors configuration - higher level
            dispatch(
              getVendors({ ...reqData, filters: filterValue }, (data) => {
                dispatch({
                  type: types.GET_FALCON_VENDORS_SUCCESS,
                  payload: { data }
                });
                dispatch(
                  updateFalconVendorReqData({
                    totalPages: data.totalPages,
                    pageNumber: data.page,
                    pageSize: data.pageSize
                  })
                );
              })
            );
          },
          onComplete: () => {
            setIsModalLoading(false);
          }
        })
      );
    }
    handleEditCleanUp();
  }, [vendorToEdit, formData, dispatch, handleEditCleanUp]);

  const getRowStyles = useCallback(
    (vendor) => ({
      boxShadow: vendorToEdit?.id === vendor?.id ? '0px 10px 30px rgba(0, 0, 0, 0.6)' : 'none',
      backgroundColor: vendorToEdit?.id === vendor?.id ? 'black' : '#1c1c1c',
      border: vendorToEdit?.id === vendor?.id ? '6px solid #1c1c1c' : '3px solid black',
      opacity: vendorToEdit && vendorToEdit?.id !== vendor?.id ? 0.6 : 1,
      transition: 'opacity 0.3s ease-in-out',
      filter: vendorToEdit && vendorToEdit?.id !== vendor?.id ? 'blur(1px)' : 'none',
      _hover: {
        backgroundColor: vendorToEdit?.id === vendor?.id ? 'black' : '#111111'
      }
    }),
    [vendorToEdit]
  );

  return (
    <Box
      position={'relative'}
      overflowX={'hidden'}
      overflowY={'auto'}
    >
      <ModalLayout
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        size="xl"
        scrollBehavior="inside"
      >
        <CreateClientModal
          onClose={closeCreateModal}
          config={config}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isConfirmUpdateConfigurationModalOpen}
        onClose={() => {
          onConfirmUpdateConfigurationModalClose();
        }}
        textAlign="center"
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={() => {
            handleSaveConfig();
          }}
          prompt={'Are you sure you want to update the Vendor configuration?'}
          isLoading={isModalLoading}
        />
      </ModalLayout>

      <VStack
        alignItems="stretch"
        gap={4}
        px={4}
        divider={<StackDivider borderColor="whiteAlpha.300" />}
      >
        <HStack justify={'space-between'}>
          <Heading size={'md'}> Vendors </Heading>
          {hasAnyAuthority(
            ROLES.ADMIN_ROLE,
            ROLES.FALCON_ADMIN,
            ROLES.FALCON_OPS_MANAGER
          ) && (
            <Button
              colorScheme={'brand'}
              size="sm"
              bg="backGround"
              border={'2px solid'}
              borderColor={'whiteAlpha.500'}
              borderRadius={'lg'}
              p={4}
              onClick={openCreateModal}
              leftIcon={<MdAdd size={'1.5rem'} />}
            >
              Create a New Vendor
            </Button>
          )}
        </HStack>

        {/*Search and Filters*/}
        <HStack
          justifyContent={'stretch'}
          alignItems="start"
          gap={2}
        >
          {!reqData.isLoading && !reqData.isError > 0 && (
            reqData.searches && (
              <SearchBox
                searches={reqData.searches}
                handleSearchChange={handleSearchChange}
              />
            )
          )}
          <HStack
            alignSelf={'flex-end'}
            justifyContent={'space-between'}
            border={'1px solid'}
            borderColor={'whiteAlpha.300'}
            bg={'backGround'}
            borderRadius={'xl'}
            p={1}
            spacing={1}
          >
            <Button
              colorScheme={'brand'}
              size="sm"
              border={viewMode === VENDORS_VIEW_MODE.CARD ? '2px solid' : '1px solid'}
              borderColor={viewMode === VENDORS_VIEW_MODE.CARD ? 'whiteAlpha.300' : 'whiteAlpha.300'}
              bg={viewMode === VENDORS_VIEW_MODE.CARD ? '#333333' : 'black'}
              borderRadius={'lg'}
              onClick={() => toggleViewMode(VENDORS_VIEW_MODE.CARD)}
              p={2}
              gap={2}
            >
              <BsViewList />
              {viewMode === VENDORS_VIEW_MODE.CARD && (
                <Text fontSize={'xs'}> Cards </Text>
              )}
            </Button>
            <Button
              colorScheme={'brand'}
              size="sm"
              border={viewMode === VENDORS_VIEW_MODE.TABLE ? '2px solid' : '1px solid'}
              borderColor={viewMode === VENDORS_VIEW_MODE.TABLE ? 'whiteAlpha.300' : 'whiteAlpha.300'}
              bg={viewMode === VENDORS_VIEW_MODE.TABLE ? '#333333' : 'black'}
              borderRadius={'lg'}
              onClick={() => toggleViewMode(VENDORS_VIEW_MODE.TABLE)}
              p={2}
              gap={2}
            >
              <MdGridOn />
              {viewMode === VENDORS_VIEW_MODE.TABLE && (
                <Text fontSize={'xs'}> Table </Text>
              )}
            </Button>
          </HStack>
        </HStack>

        {/*Pagination*/}
        {reqData?.totalPages > 1 && (
          <Pagination
            onPageChange={handlePageChange}
            currentPage={reqData.pageNumber + 1}
            totalPages={reqData.totalPages}
          />
        )}

        {vendors.isLoading ? (
          <Flex justifyContent="center" alignItems="center" w="full" h="full">
            <Spinner thickness="4px" size="lg" color="colorPrimary" />
          </Flex>
        ) : vendors.data?.length ? (
          <VStack
            alignItems="stretch"
            spacing={4}
          >
            {viewMode === VENDORS_VIEW_MODE.CARD && vendors.data.map((vendor, index) => (
              <GenericCard
                key={index}
                cardData={config.vendorToGenericCardData(vendor)}
                cardType={CARD_TYPE.FALCON_SERVICE_VENDOR_CARD}
                selected={vendor?.id === selectedVendor?.name?.value}
                maxW={'100%'}
                ActionButtons={[
                  <Button
                    colorScheme="brand"
                    color="black"
                    width="8rem"
                    size="sm"
                    fontSize="sm"
                    mt={3}
                    onClick={() => {
                      setSelectedVendor(config.vendorToGenericCardData(vendor));
                    }}
                    rightIcon={<FaArrowRightLong />}
                  >
                    Details
                  </Button>
                ]}
              />
            ))}
            {viewMode === VENDORS_VIEW_MODE.TABLE && (
              <Box position={'relative'}>
                <Heading
                  fontSize={'lg'}
                  color={'colorPrimary'}
                >
                  Configuration Table
                </Heading>
                <TableContainer
                  border={'2px solid'}
                  borderColor={'whiteAlpha.500'}
                  borderRadius={'xl'}
                  my={4}
                >
                  <Table variant={'unstyled'}>
                    <Thead>
                      <Tr
                        bg={'backGround'}
                        borderBottom="2px solid"
                        borderColor="whiteAlpha.500"
                      >
                        {vendors && vendors?.data && Object.entries(vendors.data[0]?.data)?.map(([key, field], index) => (
                          <Th
                            whiteSpace={'nowrap'}
                            key={`${key}-${index}`}
                            style={field?.position ? field?.position?.style : {}}
                          >
                            <Text fontSize="xs">
                              {field?.title}
                            </Text>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {vendors && vendors?.data && vendors.data.map((vendor, index) => (
                        <Tr key={index} {...getRowStyles(vendor)}>
                          {vendor &&
                            vendor.data &&
                            Object.entries(vendor.data)?.map(([key, field], index) => (
                              <Td
                                key={`${key}-${index}`}
                                whiteSpace={'nowrap'}
                                style={field?.position ? field?.position?.style : {}}
                              >
                                <HStack
                                  justify={field.showEditIcon ? 'space-between' : 'space-evenly'}
                                  key={`${key}-${index}`}
                                  p={0}
                                  transition="all 0.3s ease-in-out"
                                  transform={vendorToEdit?.id === vendor?.id ? 'scale(0.9)' : 'scale(1)'}
                                >
                                  {vendorToEdit?.id === vendor?.id && field.isEditable ? (
                                    <DynamicFormField
                                      key={`${index}-${field.value}`}
                                      fieldConfig={config.toDynamicFormField(field)}
                                      data={formData}
                                      handleChange={handleChange}
                                    />
                                  ) : (
                                    field.type === 'switch' ? (
                                      <Switch
                                        isDisabled
                                        size="sm"
                                        defaultChecked={field.value}
                                        colorScheme="brand"
                                        color="white"
                                      />
                                    ) : field.type === 'icon' ? (
                                      <Box
                                        color={field.color}
                                      >
                                        {field.value ? (
                                          <MdOfflineBolt size="1.2rem" />
                                        ) : (
                                          <FaDotCircle size="1.2rem" />
                                        )}
                                      </Box>
                                    ) : (
                                      <Text fontSize="xs">
                                        {field?.maskValue || field?.value || '-'}
                                      </Text>
                                    )
                                  )}
                                  {hasAnyAuthority(
                                      ROLES.ADMIN_ROLE,
                                      ROLES.FALCON_ADMIN,
                                      ROLES.FALCON_OPS_MANAGER
                                    ) &&
                                    field.showEditIcon &&
                                    (
                                      vendorToEdit?.id === vendor?.id ? (
                                        <HStack
                                          justify={'space-between'}
                                          key={`${key}-${index}`}
                                          p={0}
                                        >
                                          <Button
                                            colorScheme={'red'}
                                            size="xs"
                                            bg="black"
                                            border={'2px solid'}
                                            borderColor={'whiteAlpha.500'}
                                            borderRadius={'full'}
                                            onClick={(e) => handleEditCleanUp(e)}
                                            leftIcon={<MdOutlineCancel size={'1rem'} />}
                                            p={3}
                                          >
                                            Discard
                                          </Button>
                                          <Button
                                            colorScheme={'brand'}
                                            size="xs"
                                            bg="black"
                                            border={'2px solid'}
                                            borderColor={'whiteAlpha.500'}
                                            borderRadius={'full'}
                                            onClick={onConfirmUpdateConfigurationModalOpen}
                                            leftIcon={<IoMdCheckmark size={'1rem'} />}
                                            p={3}
                                          >
                                            Update
                                          </Button>
                                        </HStack>
                                      ) : (
                                        <Button
                                          colorScheme={'brand'}
                                          size="xs"
                                          bg="black"
                                          border={'1px solid'}
                                          borderColor={'whiteAlpha.300'}
                                          borderRadius={'lg'}
                                          p={0}
                                          onClick={(e) => handleEditMode(e, vendor)}
                                        >
                                          <MdEdit />
                                        </Button>
                                      )
                                    )}
                                </HStack>
                              </Td>
                            ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )
            }
          </VStack>
        ) : (
          <Box p={4} borderRadius="xl" bg={'backGround'} borderWidth="1px" borderColor={'whiteAlpha.300'}>
            <Text color="white" fontSize="md">
              No Vendors found
            </Text>
          </Box>
        )}
      </VStack>
    </Box>
  );
};